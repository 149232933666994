<script lang="jsx">
export default {
  data(){
    return {
      dateRange:null,
      formData:{
        buyerName:undefined,
        startDate: undefined,
        endDate: undefined
      }
    }
  },
  watch: {
    dateRange(val) {
      if (val && val.length) {
        this.formData.startDate = val[0]
        this.formData.endDate = val[1]
      } else {
        this.formData.startDate = this.formData.endDate = undefined
      }
    },
  },
  methods:{
    handleQuery(){
      this.$emit('query', this.formData)
    },
    resetFields(){
      this.$refs.form.resetFields()
      this.dateRange = []
      setTimeout(() => {
        this.handleQuery()
      }, 10)
    }
  },
  render(){
    return (
    <fin-form label-position={this.config.searchLabelPosition} inline={this.config.searchInline} props={{model: this.formData}} class="mt-24" ref="form" label-width="120px">
      <fin-form-item label="买方企业名称" prop="buyerName">
        <fin-input clearable class="w-192" value={this.formData.buyerName} on-input={v=>this.formData.buyerName=v} placeholder={'买方企业名称'}></fin-input>
      </fin-form-item>
      <fin-form-item label="上传日期" prop="dateRange">
        <fin-date-picker
          clearable
          value={this.dateRange} on-input={v=>this.dateRange=v}
          value-format="yyyy-MM-dd HH:mm:ss"
          class="w-400"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
        >
        </fin-date-picker>
      </fin-form-item>
      <fin-form-item class="text-right">
        <fin-button type="primary" on-click={this.handleQuery.bind(this)}>查询</fin-button>
        <fin-button plain class="ml-10" on-click={this.resetFields}>重置</fin-button>
      </fin-form-item>
    </fin-form>)
  }
}
</script>
