/**
 * 借款相关
 */
export default [
  {
    /**
     * 融资记录
     * http://10.222.95.129/#/view/q2RE9Bzg
     */
    name: 'queryFinanceList',
    method: 'post',
    url: '/api/page/finance/loan/list',
  },
  {
    /**
     * 未结清的采购单列表
     * http://10.222.95.129/#/view/P814jm2q
     */
    name: 'queryPurchaseOrderList',
    method: 'post',
    url: '/api/page/purchase/order/list',
  },
  {
    /**
     * 动态表单
     * http://10.222.95.129/#/view/E8rZZv80
     */
    name: 'preLoan',
    method: 'post',
    url: '/api/page/finance/apply/preLoan',
  },
  {
    /**
     * 动态表单preLoan
     * http://10.222.95.129/#/view/nzdaJD2j
     */
    name: 'predict',
    method: 'post',
    url: '/api/page/finance/apply/predict',
  },
  {
    /**
     * 确认签署合同并支付
     * http://10.222.95.129/#/view/r2jA66zG
     */
    name: 'applySubmit',
    method: 'post',
    url: '/api/page/finance/apply/submit',
  },
  {
    /**
     * 融资单详情
     * http://10.222.95.129/#/view/nzdanM2j
     */
    name: 'financeDetail',
    method: 'post',
    url: '/api/page/finance/detail',
  },
  {
    /**
     * 查询关联单
     * http://10.222.95.129/#/view/L2klYL8P
     */
    name: 'financeAssociated',
    method: 'post',
    url: '/api/page/finance/associated',
  },
  {
    /**
     * 查询订单信息
     */
    name: 'queryOrderInfo',
    method: 'post',
    url: '/api/page/finance/order/Info',
  },
  {
    /**
     * 宜发展
     * 确认签署合同并支付 applySubmit
     */
    name: 'contractSubmitYFZ',
    method: 'post',
    url: '/api/page/finance/apply/contract/submit',
  },
  {
    /**
     * 宜发展
     * 后端存储订单信息
     */
    name: 'externalSubjectInit',
    method: 'post',
    url: '/api/page/finance/apply/externalSubjectInit',
  },
]
