//准入
export default [
  {
    path: '/open',
    name: 'open',
    meta: {
      bodyColor: '#fff',
    },
    component: () => import('../views/open/index.vue'),
  },
  {
    path: '/flow/:node/:step?',
    name: 'flow',
    meta: {
      bodyColor: '#fff',
    },
    component: () => import('../views/open/Flow.vue'),
  },
  // 合同签署中转页
  {
    path: '/contract-middle',
    name: 'contractMiddle',
    component: () => import('@/views/contract/components/sign/Middle.vue'),
    meta: {
      bodyColor: '#fff',
      noHeader: true,
      needLogin: false,
    },
  },
  // 合同签署模拟第三方页
  {
    path: '/contract-third',
    name: 'contractThird',
    component: () => import('@/views/contract/components/sign/Third.vue'),
    meta: {
      bodyColor: '#fff',
      noHeader: true,
      needLogin: false,
    },
  },
]
