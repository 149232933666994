<template>
  <div>
    <jdb-table
      :columns="columns"
      :list="dataList"
      :loading="loading"
      :pagination="pagination"
      @page-change="changePage"
      @size-change="changePageSize"
    >
    </jdb-table>
  </div>
</template>

<script lang="jsx">
import TableVue from '@/views/components/Table.vue'
import normalMixin from '@/mixins/normalMixin'
// import { userRepayStatus } from '@/utils/enum'
import service from '@/service'
export default {
  name:'financingTableYS',
  mixins: [normalMixin],
  components:{
    jdbTable: TableVue,
  },
  data(){
    return{
      loanColumns: [
        {
          name: '融资单编号',
          dataIndex: 'financeNo',
        },
        {
          name: '应收账款编号',
          dataIndex: 'bizNo',
        },
        {
          name: '买方名称',
          dataIndex: 'buyerName',
          width: '160px'
        },
        {
          name: '融资金额(元)',
          dataIndex: 'amount',
          width: '160px',
          align: 'right',
          render: ({ row }) => this.formatCurrency(row.amount)
        },
        {
          name: '状态',
          dataIndex: 'userRepayStatus',
          width: '160px',
          render: ({ row }) => this.enumFilter(row.userRepayStatus, 'financeSettleState'),
        },
        {
          name: '操作',
          dataIndex: 'financeNo',
          width: '78px',
          render: ({ row }) => {
            let detailPath = { name: 'loan-detail', params: { financeNo: row.financeNo } }
            let {resolved} = this.$router.resolve(detailPath)
            return <div>
              <router-link target={resolved.matched.length > 1 ? undefined: '_blank'} to={detailPath}>详情</router-link>
            </div>
          },
        },
      ],
      confirmColumns: [
        {
          name: '融资单编号',
          dataIndex: 'financeNo',
        },
        {
          name: '应收账款编号',
          dataIndex: 'bizNo',
          width: '160px',
          align: 'right'
        },
        {
          name: '公司名称',
          dataIndex: 'buyerName',
          width: '160px',
          align: 'right'
        },
        {
          name: '融资金额(元)',
          dataIndex: 'amount',
          width: '160px',
          align: 'right',
          render: ({ row }) => this.formatCurrency(row.amount)
        },
        {
          name: '本次还款金额(元)',
          dataIndex: 'repayAmount',
          width: '160px',
          align: 'right',
          render: ({ row }) => this.formatCurrency(row.repayAmount?.totalAmount)
        },
        {
          name: '状态',
          dataIndex: 'userRepayStatus',
          width: '160px',
          align: 'right',
          render: ({ row }) => this.enumFilter(row.userRepayStatus, 'financeSettleState'),
        },
        {
          name: '操作',
          dataIndex: 'financeNo',
          width: '78px',
          render: ({ row }) => {
            let detailPath = { name: 'loan-detail', params: { financeNo: row.financeNo } }
            let {resolved} = this.$router.resolve(detailPath)
            return <div>
              <router-link target={resolved.matched.length > 1 ? undefined: '_blank'} to={{ name: 'loan-detail', params: { financeNo: row.financeNo } }}>详情</router-link>
            </div>
          },
        }
      ],
    }
  },
  computed:{
    columns(){
      const columnsObj = {
        CONFIRM: this.confirmColumns,
        LOAN: this.loanColumns
      }
      const { productRole } = this.$store.getters.productInfo
      return productRole&&columnsObj[productRole]
    }
  },
  methods:{
     async doQuery(pagination) {
      let { pageParam, repayFinanceList } = await service
        .queryRepayFinanceYS({
          productCode: this.productCode,
          repayNo: this.$route.params.repayNo,
          pageParam: pagination,
        })
        .executeSerial()
      this.dataList = repayFinanceList
      this.pagination.total = pageParam.total
    },
  },
  mounted(){
     this.handleQuery()
  }
}
</script>

<style></style>
