<template>
  <div>
    <jdb-table
      :columns="columns"
      :list="dataList"
      :loading="loading"
      :pagination="pagination"
      @page-change="changePage"
      @size-change="changePageSize"
    >
    </jdb-table>
  </div>
</template>

<script lang="jsx">
import TableVue from '@/views/components/Table.vue'
import normalMixin from '@/mixins/normalMixin'
import service from '@/service'
// import { repayState } from '@/utils/enum'
export default {
  name:'financingTableCG',
  mixins: [normalMixin],
  components:{
    jdbTable: TableVue,
  },
  data(){
    return{
      columns: [
        {
          name: '融资单编号',
          dataIndex: 'financeNo',
        },
        {
          name: '已还金额(元)',
          dataIndex: 'repayAmount',
          width: '160px',
          align: 'right',
          render: ({ row }) => this.formatCurrency(row.repayAmount.totalAmount),
        },
        {
          name: '含本金(元)',
          dataIndex: 'repayAmount',
          width: '160px',
          align: 'right',
          render: ({ row }) => this.formatCurrency(row.repayAmount.capAmount),
        },
        {
          name: '含利息(元)',
          dataIndex: 'repayAmount',
          width: '160px',
          align: 'right',
          render: ({ row }) => this.formatCurrency(row.repayAmount.intAmount),
        },
        {
          name: '含罚息(元)',
          dataIndex: 'repayAmount',
          width: '160px',
          align: 'right',
          render: ({ row }) => this.formatCurrency(row.repayAmount.fineAmount),
        },
        {
          name: '操作',
          dataIndex: 'repayAmount',
          width: '78px',
          render: ({ row }) => {
            let detailPath = { name: 'loan-detail', params: { financeNo: row.financeNo } }
            let {resolved} = this.$router.resolve(detailPath)
            return <div>
              <router-link target={resolved.matched.length > 1 ? undefined: '_blank'} to={detailPath}>明细</router-link>
            </div>
          },
        },
      ],
    }
  },
  methods:{
    async doQuery(pagination) {
      let { pageParam, repayFinanceList } = await service
        .queryRepayFinance({
          productCode: this.productCode,
          repayNo: this.$route.params.repayNo,
          pageParam: pagination,
        })
        .executeSerial()
      this.dataList = repayFinanceList
      this.pagination.total = pageParam.total
    },
  },
  mounted(){
    this.handleQuery()
  }
}
</script>
