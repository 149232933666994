<script lang="jsx">
export default {
  data(){
    return {
      dateRange:null,
      formData:{
        financeNo:undefined,
        repayDateStart: undefined,
        repayDateEnd: undefined
      }
    }
  },
  watch: {
    dateRange(val) {
      if (val && val.length) {
        this.formData.repayDateStart = this.formatDate(val[0])
        this.formData.repayDateEnd = this.formatDate(val[1])
      } else {
        this.formData.repayDateStart = this.formData.repayDateEnd = undefined
      }
    },
  },
  methods:{
    handleQuery(){
      this.$emit('query', this.formData)
    },
    resetFields(){
      this.$refs.form.resetFields()
      this.dateRange = []
      setTimeout(() => {
        this.handleQuery()
      }, 10)
    }
  },
  render(){
    return (
    <fin-form  {...{props:{model: this.formData}}} ref="form" label-width="120px">
      <fin-form-item label="融资编号" prop="financeNo">
        <fin-input clearable class="w-192" value={this.formData.financeNo} on-input={v=>this.formData.financeNo=v} placeholder={'融资编号'}></fin-input>
      </fin-form-item>
      <fin-form-item label="应还日期" prop="dateRange">
        <fin-date-picker
          clearable
          value={this.dateRange} on-input={v=>this.dateRange=v}
          class="w-400"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
        >
        </fin-date-picker>
      </fin-form-item>
      <fin-form-item class="text-right">
        <fin-button type="primary" on-click={this.handleQuery.bind(this)}>查询</fin-button>
        <fin-button plain class="ml-10" on-click={this.resetFields}>重置</fin-button>
      </fin-form-item>
    </fin-form>)
  }
}
</script>
