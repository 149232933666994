<script lang="jsx">
import MaskInput from '@/views/components/form/MaskInput.vue'
import { Regex } from '@/utils/const.js'
export default  {
  props:{
    title:String,
    origin:Object
  },
  data() {
    return {
      formData:{
        personName: '',
        relationship: '',
        personCellphone: '',
      },
      rules:{
        personName: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        relationship: [{ required: true, message: '请选择', trigger: 'blur' }],
        personCellphone: [
          { required: true, message: '请输入', trigger: 'blur' },
          {
            type: 'mask',
            validator: (field, value) => {
              if (!Regex.mobile.test(value)) {
                return new Error('手机号格式错误')
              }

              return true
            },
          },
        ],
      },
    }
  },
  methods:{
    handleClose(){
      this.$emit('close')
    },
    onSubmit(){
      this.$refs.form.validate(r=>{
        if(r){
          if(this.formData.personName.match(/[*]/g)){
            this.$message.error('请输入完整姓名');
          }else if(!Regex.mobile.test(this.formData.personCellphone)) {
            this.$message.error('请输入完整手机号');
          }else{
            this.$emit('submit', this.formData, this.handleClose.bind(this))
          }
        }
      })
    }
  },
  render(){
    return <div>
      <p class="mt-8 mb-24">{this.title}</p>
      <fin-form
        ref="form"
        hide-required-asterisk
        label-position={this.config.searchLabelPosition ? this.config.searchLabelPosition : 'right'}
        label-width="84px"
        props={{ model: this.formData }}
        rules={this.rules}
      >
        <fin-form-item label="姓名" prop="personName">
          <MaskInput value={this.formData.personName} on-input={v=>this.formData.personName=v} maxlength="50" placeholder="请输入"></MaskInput>
        </fin-form-item>
        <fin-form-item label="类型" prop="relationship">
          <fin-select value={this.formData.relationship} on-input={v=>this.formData.relationship=v} style="width:100%">
          {this.enumData['relationship']?.map(m=>{
            return <fin-option label={m.itemName} value={m.itemCode}></fin-option>
          })}
          </fin-select>
        </fin-form-item>
        <fin-form-item label="手机号" prop="personCellphone">
          <MaskInput value={this.formData.personCellphone} on-input={v=>this.formData.personCellphone=v} maxlength="11" placeholder="请输入"></MaskInput>
        </fin-form-item>
      </fin-form>
      <p class="mt-24 text-right">
        <fin-button class="custom-cancel" on-click={this.handleClose}>取 消</fin-button>
        <fin-button type="primary" on-click={this.onSubmit}>确 定</fin-button>
      </p>
    </div>
  },
  created(){
    if(this.origin){
      this.formData = JSON.parse(JSON.stringify(this.origin))
    }
  }
}
</script>
