import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import Sub from './Sub.vue'
import { defaultRouter } from './router'
import store from './store'
import '@/assets/css/tailwindcss.css'
import config from './config/index'
import './global.jsx'

store.dispatch('system/setConfig', config)
Vue.use(VueI18n)
// 准备翻译的语言环境信息
const messages = {
  en: {},
  zh: {},
}
// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
  locale: 'ja', // 设置地区
  messages, // 设置地区信息
})
const vueOptions = {
  router: defaultRouter,
  store,
  el: '#app',
  i18n,
}

let instance = null

function render(props = {}) {
  return new Vue({
    ...vueOptions,
    ...props,
  })
}

if (!window.proxy?.__POWERED_BY_QIANKUN__) {
  window.root = instance = render({
    render: h => h(App),
  })
} else {
  /**
   * bootstrap 只会在微应用初始化的时候调用一次，下次微应用重新进入时会直接调用 mount 钩子，不会再重复触发 bootstrap。
   * 通常我们可以在这里做一些全局变量的初始化，比如不会在 unmount 阶段被销毁的应用级别的缓存等。
   */
  const bootstrap = function() {}
  /**
   * 应用每次进入都会调用 mount 方法，通常我们在这里触发应用的渲染方法
   *
   * productCode 在jnos注册的产品code
   * title 标题栏展示的title
   * gatewayUrl jnos网关地址
   * gatewayAppCode jnos网关AppCode
   * imageDomain 图片服务域名前缀，数组
   * loginDomain 登录域名 如: //sso-uat.crxn.cn
   * loginUrl 登录地址，拼接了returnUrl
   * logoutUrl 退出登录地址
   * logoUrl 全局LOGO地址 iconUrl全局favicon.icn地址
   * logoutMethod 退出登录方法，入参是对应的returnUrl
   * loginMethod 登录方法，入参是对应的returnUrl
   * noAuthPage 无权限页地址
   * notVenderPage 非商家账号跳转地址
   * settleInPage 入驻页地址
   *
   */
  const mount = function(props) {
    let outerData = props.CONSTANTS
    store.dispatch('outer/setOuterData', outerData)

    instance = render({
      render: h => h(Sub),
      ...props,
    })
  }

  /**
   * 应用每次 切出/卸载 会调用的方法，通常在这里我们会卸载微应用的应用实例
   */
  const unmount = function() {
    debugger
    instance.$destroy()
    instance.$el.innerHTML = ''
    instance = null
  }

  /**
   * 可选生命周期钩子，仅使用 loadMicroApp（手动加载微应用） 方式加载微应用时生效
   */
  const update = function(props) {
    console.log('update props', props)
  }

  window.proxy.__LIFECYCLE__ = {
    bootstrap,
    mount,
    unmount,
    update,
  }
}
