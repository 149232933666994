export default [
  {
    /**
     *  用款凭证提交页-预提交信息（url:/api/page/finance/cashvoucher/preSubmit）
     * http://mock.jdfmgt.com/interface/detail?projectId=62ea1facf54b57a7351ffc86&interfaceId=62f61a61f54b57a7351ffe36
     */
    name: 'cashvoucherPreSubmit',
    method: 'post',
    url: '/api/page/cnooc/cashvoucher/preSubmit',
  },
  {
    /**
     *  用款凭证-提交（url:/api/page/finance/cashvoucher/submit）
     * http://mock.jdfmgt.com/interface/detail?projectId=62ea1facf54b57a7351ffc86&interfaceId=62f61a61f54b57a7351ffe36
     */
    name: 'cashvoucherSubmit',
    method: 'post',
    url: '/api/page/cnooc/cashvoucher/submit',
  },
  {
    /**
     *  用款凭证-详情（url:/api/page/cnooc/cashvoucher/details）
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62fcaa53855696ad35fc3ec0
     */
    name: 'getCashvoucherDetail',
    method: 'post',
    url: '/api/page/cnooc/cashvoucher/details',
  },
  {
    /**
     *  查询账户（url:/api/page/access/virtualaccount/get）
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62fef155855696ad35fc42d8
     */
    name: 'getVitrualAccountInfo',
    method: 'post',
    url: '/api/page/access/virtualaccount/get',
  },
  {
    /**
     *  账户开通-授权开通（url:/api/page/access/virtualaccount/confirm）
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62ff5630f54b57a7352000ca
     */
    name: 'configVirtualAccount',
    method: 'post',
    url: '/api/page/access/virtualaccount/confirm',
  },
  {
    /**
     *  账户开通-下一步（url:/api/page/access/virtualaccount/next）
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62ff56ceaf50ba51ae0c9956
     */
    name: 'nextVirtualAccount',
    method: 'post',
    url: '/api/page/access/virtualaccount/next',
  },
  {
    /**
     *  授信申请-查询（url:/api/page/cnooc/credit/query）
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=63048e24af50ba51ae0c9ac0
     */
    name: 'queryCreditInfoForZhy',
    method: 'post',
    url: '/api/page/cnooc/credit/query',
  },
  {
    /**
     *  授信-授信申请（url:/api/page/cnooc/credit/startAudit）
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6304912ff54b57a735200244
     */
    name: 'startAuditForZhy',
    method: 'post',
    url: '/api/page/cnooc/credit/startAudit',
  },
  {
    /**
     *  授信申请-补充材料（url:/api/page/cnooc/credit/additional）
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6304e362f54b57a7352002ac
     */
    name: 'additionalForZhy',
    method: 'post',
    url: '/api/page/cnooc/credit/additional',
  },
  {
    /**
     *  融资申请查询动态表单（url:/api/page/cnooc/finance/query）
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6304e362f54b57a7352002ac
     */
    name: 'preLoanForZhy',
    method: 'post',
    url: '/api/page/cnooc/finance/query',
  },
  {
    /**
     *  融资申请-确认融资（url:/api/page/cnooc/finance/submit）
     * https://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6311922579621f424e8ae3d5
     */
    name: 'applySubmitForZhy',
    method: 'post',
    url: '/api/page/cnooc/finance/submit',
  },
  {
    /**
     *  融资申请-重置（url:/api/page/cnooc/finance/reset）
     * https://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6311936679621f424e8ae3d7
     */
    name: 'resetLoanApplyForZhy',
    method: 'post',
    url: '/api/page/cnooc/finance/reset',
  },
  {
    /*  借款初始化订单编号
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6304e362f54b57a7352002ac
     */
    name: 'subjectInit',
    method: 'post',
    url: '/api/page/finance/apply/subjectInit',
  },
  {
    /*  服务费汇总查询待支付金额和笔数，已支付金额接口
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=63203039697edf484e5dfb78
     */
    name: 'queryServiceFeeAmtSummary',
    method: 'post',
    url: '/api/page/cnooc/serviceFee/queryAmtSummary',
  },
  {
    /*  查询服务费账户及余额
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=63204805697edf484e5dfb90
     */
    name: 'queryServiceFeeAccountBalance',
    method: 'post',
    url: '/api/page/cnooc/serviceFee/queryAccountBalance',
  },
  {
    /*  待支付列表分页查询接口（面客端）
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=63203039697edf484e5dfb78
     */
    name: 'queryServiceFeeUnpaid',
    method: 'post',
    url: '/api/page/cnooc/serviceFee/queryUnpaid',
  },
  {
    /*  服务费详情接口（面客端）
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=63204c9f79621f424e8ae7fe
     */
    name: 'queryServiceFeeDetail',
    method: 'post',
    url: '/api/page/cnooc/serviceFee/detail',
  },
  {
    /*  已支付缴费记录分页查询接口（面客端）
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=63204ef079621f424e8ae800
     */
    name: 'queryServiceFeePaid',
    method: 'post',
    url: '/api/page/cnooc/serviceFee/queryPaid',
  },
  {
    /*  服务费支付的预览接口
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=63205118697edf484e5dfb99
     */
    name: 'queryServiceFeeBalanceAndTelephone',
    method: 'post',
    url: '/api/page/cnooc/serviceFee/queryBalanceAndTelephone',
  },
  {
    /*  发起支付接口
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=632073a1697edf484e5dfba9
     */
    name: 'transServiceFeeApply',
    method: 'post',
    url: '/api/page/cnooc/serviceFee/transApply',
  },
  {
    /**
     * 汇总查询待开票金额、笔数和已开票金额接口
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6322ff21697edf484e5dfdd6
     */

    name: 'queryInvoiceAmtSummary',
    method: 'post',
    url: '/api/page/cnooc/invoice/queryAmtSummary',
  },
  {
    /**
     * 查询发票抬头
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6323080f79621f424e8aea0d
     */

    name: 'queryTitleList',
    method: 'post',
    url: '/api/page/cnooc/invoiceTitle/queryTitleList',
  },
  {
    /**
     * 待开票申请列表页分页查询接口
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=63230b6279621f424e8aea0f
     */

    name: 'queryUninvoiced',
    method: 'post',
    url: '/api/page/cnooc/invoice/queryUninvoiced',
  },
  {
    /**
     * 开票申请记录列表分页查询接口
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=63231c6e79621f424e8aea19
     */

    name: 'queryInvoiced',
    method: 'post',
    url: '/api/page/cnooc/invoice/queryInvoiced',
  },
  {
    /**
     * 发票记录详情接口
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=63232374697edf484e5dfdef
     */

    name: 'queryInvoiceDetail',
    method: 'post',
    url: '/api/page/cnooc/invoice/invoiceDetail',
  },
  {
    /**
     * 查询发票抬头编辑页信息
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6323282f79621f424e8aea20
     */

    name: 'queryInvoiceTitleByType',
    method: 'post',
    url: '/api/page/cnooc/invoiceTitle/queryByType',
  },
  {
    /**
     * 保存发票抬头
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=63232ca679621f424e8aea24
     */

    name: 'saveInvoiceTitle',
    method: 'post',
    url: '/api/page/cnooc/invoiceTitle/saveOrUpdate',
  },
  {
    /**
     * 开票申请接口
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=63232ea479621f424e8aea27
     */

    name: 'applyInvoice',
    method: 'post',
    url: '/api/page/cnooc/invoice/apply',
  },
]
