import getThemeVars from './color'

export default {
  name: '默认主题',
  vars: getThemeVars({
    'primary-color': '#0061A5',
    'success-color': '#269c39',
    'warning-color': '#ff9900',
    'danger-color': '#dd002b',
    'info-color': '#a1a5af',
  }),
}
