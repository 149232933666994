export default {
  data() {
    return {
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        pageSizes: [10, 20, 50, 100],
        layout: 'slot, ->,total, prev, pager, next, sizes',
      },
      dataList: [],
      loading: false,
    }
  },
  methods: {
    async handleQuery() {
      this.pagination.pageNum = 1
      this.process()
    },
    async changePageSize(ps) {
      this.pagination.pageNum = 1
      this.pagination.pageSize = ps
      this.process()
    },
    async changePage(p) {
      this.pagination.pageNum = p
      this.process()
    },
    async process() {
      this.loading = true
      try {
        await this.doQuery({
          ...this.pagination,
          layout: undefined,
          pageSizes: undefined,
          total: undefined,
        })
        // eslint-disable-next-line no-useless-catch
      } catch (e) {
        throw e
      } finally {
        this.loading = false
      }
    },
  },
}
