/**
 * 接口文档地址
 */
export default [
  {
    /*
     * 发送注册短信验证码
     * http://10.222.95.129/#/view/aBXgyE2G
     */
    name: 'registerSms',
    method: 'post',
    url: '/api/page/register/noAuth/registerSms',
  },
  {
    /*
     * 注册
     * http://10.222.95.129/#/view/ZB2lyd8D
     */
    name: 'register',
    method: 'post',
    url: '/api/page/register/noAuth/register',
  },
]
