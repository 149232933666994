import { setProductCode, getProductCode } from '@/utils'

const state = {
  allProducts: undefined,
  currentProductCode: getProductCode(),
}

const mutations = {
  SET_ALL: (state, productList) => {
    state.allProducts = productList
  },
  SET_PRODUCT_CODE: (state, productCode) => {
    state.currentProductCode = productCode
    setProductCode(productCode)
  },
}

const actions = {
  setProduct({ commit }, productList) {
    commit('SET_ALL', productList)
  },
  setProductCode({ commit }, productCode) {
    commit('SET_PRODUCT_CODE', productCode)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
