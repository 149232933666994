<script lang="jsx">
export default {
  name: 'ui-table',
  props: {
    columns: Array,
    list: Array,
    pagination: Object,
    loading: Boolean,
    emptyText: {
      type: String,
      default: '暂无数据',
    },
    size: String,
    width: Number,
    height: [Number, String],
    hideOnSinglePage:{
      type:Boolean,
      default: false
    },
    isAdaptiveHeight:{
      type:Boolean,
      default: false
    }
  },
  computed:{
    fixedHeightClass(){
      return{
        fixedHeight: !this.isAdaptiveHeight
      }
    }
  },
  methods: {
    pageChange(args) {
      this.$emit('page-change', args)
    },
    sizeChange(args) {
      this.$emit('size-change', args)
    },
    toggleRowSelection(...args){
      this.$refs.table.toggleRowSelection.apply(this.$refs.table, args)
    }
  },
  render() {
    let columns = this.columns.map(m => {
      if (m.render) {
        return (
          <fin-table-column
            {...{ attrs: m }}
            width={m.width}
            fixed={m.fixed}
            type={m.type}
            align={m.align || 'left'}
            prop={m.dataIndex}
            label={m.name}
            {...{
              scopedSlots: {
                default: props => {
                  return m.render(props)
                },
              },
            }}
          />
        )
      }
      return (
        <fin-table-column
          {...{ attrs: m }}
          prop={m.dataIndex}
          align={m.align || 'left'}
          fixed={m.fixed}
          width={m.width}
          type={m.type}
          label={m.name}
        />
      )
    })
    if(!this.loading) {
      let node = this.$slots.empty

      if(!node) {
        node = <div class="my-100" slot="empty">
          <div class="empty-bg" />
          <p>{this.emptyText}</p>
        </div>
      }else{
        node = <template slot="empty">{node}</template>
      }
      columns.push(node)
    }

    let page = this.pagination ? (
      <fin-pagination
        class="mt-20"
        page-sizes={this.pagination.pageSizes}
        total={this.pagination.total}
        hide-on-single-page={this.hideOnSinglePage}
        current-page={this.pagination.pageNum}
        page-size={this.pagination.pageSize}
        on-current-change={this.pageChange}
        on-size-change={this.sizeChange}
        layout={this.pagination.layout}
      >
      {this.$slots.pagination}
      </fin-pagination>
    ) : null

    return (
      <div class="ui-table">
        <fin-table
          ref="table"
          class={this.fixedHeightClass}
          width={this.width}
          height={this.height}
          data={this.list}
          size={this.size}
          v-loading={this.loading}
          {...{attrs: this.$attrs}}
          on={this.$listeners}
          valign="top"
        >
          {columns}
        </fin-table>
        {page}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.ui-table {
  .fixedHeight.fin-table {
    min-height: 372px;
  }
  .empty-bg {
    background: url(../../assets/images/empty.png) no-repeat center;
    width: 82px;
    height: 95px;
    background-size: contain;
    margin: 0 auto;
  }
}
</style>
