//融资
export default [
  {
    path: '/loan/apply/:bizNo',
    name: 'loan-byno',
    component: () => import('../views/loan/index.vue'),
  },
  {
    path: '/loan/apply',
    name: 'loan',
    component: () => import('../views/loan/index.vue'),
  },
  {
    path: '/loan/sign/:financeNo',
    name: 'loan-sign',
    component: () => import('../views/loan/LoanSignContract.vue'),
  },
  {
    path: '/loan/result/:financeNo',
    name: 'loan-result',
    component: () => import('../views/loan/LoanResult.vue'),
  },
]
