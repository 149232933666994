/**
 * 还款相关
 */
export default [
  {
    /**
     * 还款记录
     * http://10.222.95.129/#/view/q2RE9Bzg
     */
    name: 'queryRepayRecord',
    method: 'post',
    url: '/api/page/repay/query/queryRepayRecord',
  },
  {
    /**
     * 查询用户还款汇总信息
     * http://10.222.95.129/#/view/7zLNPB8Q
     */
    name: 'queryUserRepaySummary',
    method: 'post',
    url: '/api/page/repay/query/queryUserRepaySummary',
  },
  {
    /**
     * 查询用户还款配置
     * http://10.222.95.129/#/view/nzDOxZXp
     */
    name: 'queryRepayForm',
    method: 'post',
    url: '/api/page/repay/query/queryRepayForm',
  },
  {
    /**
     * 查询用户还款配置
     * http://10.222.95.129/#/view/pX9WNB2r
     */
    name: 'doRepay',
    method: 'post',
    url: '/api/page/repay/doRepay',
  },
  {
    /**
     * 查询还款详情
     * http://10.222.95.129/#/view/7zL6O68Q
     */
    name: 'queryRepayRecordByRepayNo',
    method: 'post',
    url: '/api/page/repay/query/queryRepayRecordByRepayNo',
  },
  {
    /**
     * 查询还款单融资记录-采购
     * http://10.222.95.129/#/view/BXgKbxXG
     */
    name: 'queryRepayFinance',
    method: 'post',
    url: '/api/page/repay/query/queryRepayFinance',
  },
  {
    /**
     * 查询还款单融资记录-应收
     * http://10.222.95.129/#/view/BXgKbxXG
     */
    name: 'queryRepayFinanceYS',
    method: 'post',
    url: '/api/page/repay/query/queryRepayYSFinance',
  },
  {
    /**
     * 查询借款单还款详情
     * http://10.222.95.129/#/view/BXgKbxXG
     */
    name: 'queryFinanceRepayDetail',
    method: 'post',
    url: '/api/page/repay/query/queryFinanceRepayDetail',
  },
]
