export default [
  {
    /**
     * 实名流程查询
     * http://10.222.95.129/#/view/mzW1Pg8l
     */
    name: 'queryRealNameProcess',
    method: 'post',
    url: '/api/page/realName/queryRealNameProcess',
  },
  {
    /**
     * 查询法人信息（url: /api/page/realName/queryLegal）
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=627a29601627a00967237f18&sso_token=JIf2ZXeB4b5KrNujoyUc7zSO9PCxDkp1i3l
     * @params data Object
     */
    name: 'queryLegal',
    method: 'post',
    url: '/api/page/realName/queryLegal',
  },
  {
    /**
     * 返回到某个节点（url: /api/page/realname/apply/backto）
     * http://10.222.95.129/#/view/P81r33Xq
     */
    name: 'applyBackto',
    method: 'post',
    url: '/api/page/realname/apply/backto',
  },
  {
    /**
     * 提交证件
     * http://10.222.95.129/#/view/JzAMW4zp
     */
    name: 'summitCompanycert',
    method: 'post',
    url: '/api/page/realname/companycert/summit',
  },
  {
    /**
     * 查询提交的证件
     * http://10.222.95.129/#/view/d2qAEN2w
     */
    name: 'getCompanycert',
    method: 'post',
    url: '/api/page/realname/companycert/get',
  },
  {
    /**
     * 识别工商营业执照
     * http://10.222.95.129/#/view/VzNmrjXY
     */
    name: 'ocrLicense',
    method: 'post',
    url: '/api/page/realname/ocr/license',
  },
  {
    /**
     * 识别身份证正面
     * http://10.222.95.129/#/view/PzmxpqXJ
     */
    name: 'ocrIdfront',
    method: 'post',
    url: '/api/page/realname/ocr/idfront',
  },
  {
    /**
     * 识别身份证反面
     * http://10.222.95.129/#/view/N2ejB58e
     */
    name: 'ocrIdback',
    method: 'post',
    url: '/api/page/realname/ocr/idback',
  },
  {
    /**
     * 获取开户行列表
     * http://10.222.95.129/#/view/qkXOr98D
     */
    name: 'bankList',
    method: 'post',
    url: '/api/page/realname/bank/list',
  },
  {
    /**
     * 获取省市列表
     * http://10.222.95.129/#/view/a85yZJ8Z
     */
    name: 'bankProvince',
    method: 'post',
    url: '/api/page/realname/bank/province',
  },
  {
    /**
     * 获取支行列表
     * http://10.222.95.129/#/view/xXaGqY2o
     */
    name: 'bankBranch',
    method: 'post',
    url: '/api/page/realname/bank/branch',
  },
  {
    /**
     * 获取对公账号信息
     * http://10.222.95.129/#/view/m8wlLNXk
     */
    name: 'smallamtGet',
    method: 'post',
    url: '/api/page/realname/smallamt/get',
  },
  {
    /**
     * 提交对公账号
     * http://10.222.95.129/#/view/E8rEjb20
     */
    name: 'smallamtSummit',
    method: 'post',
    url: '/api/page/realname/smallamt/summit',
  },
  {
    /**
     * 小额对公打款验证
     * http://10.222.95.129/#/view/qXb3E1zv
     */
    name: 'smallamtVerify',
    method: 'post',
    url: '/api/page/realname/smallamt/verify',
  },
  {
    /**
     * 查询用户产品下的实名类型 (url:/api/page/realName/queryUserProductRealNameInfo)
     * http://10.222.95.129/#/view/7zLp9ZzQ
     */
    name: 'queryUserProductRealNameInfo',
    method: 'post',
    url: '/api/page/realName/queryUserProductRealNameInfo',
  },
  {
    /**
     * 融资段-企业信息-法人信息 (url:/api/page/realName/queryLegal)
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=627a29601627a00967237f18
     */
    name: 'queryLegal',
    method: 'post',
    url: '/api/page/realName/queryLegal',
  },
]
