export default [
  {
    /**
     * 中海信托非邀请码产品开通流程
     *
     */
    name: 'goNoInviteForZhxt',
    method: 'post',
    url: '/api/page/zhxt/productOpen/customerFetch',
  },
  {
    /**
     * 中海信托查询支行
     *
     */
    name: 'queryBanchForZhxt',
    method: 'post',
    url: '/api/page/realname/bank/branch',
  },
  {
    /**
     * 中海信托查询虚户开通状态
     *
     */
    name: 'queryAccessAccountStateForZhxt',
    method: 'post',
    url: '/api/page/access/account/queryAccessAccountState',
  },
  {
    /**
     * 中海信托开通账户
     *
     */
    name: 'openAccessAccountForZhxt',
    method: 'post',
    url: '/api/page/access/account/openAccessAccount',
  },
]
