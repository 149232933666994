import { getRestUrl } from '../api'
/**
 * 公共接口
 * */
export default [
  {
    /**
     * 生成图片验证码
     * http://10.222.95.129/#/view/Br2jVn2G
     */
    name: 'verifyCodeImgGenerate',
    method: 'post',
    url: '/api/page/imageVerifyCode/noAuth/imageVerifyCodeGenerate',
  },
  {
    /**
     * 文件上传，返回文件编码
     * http://10.222.95.129/#/view/K2Zn1B2v
     */
    name: 'uploadFile',
    method: 'post',
    url: '/api/page/file/manage/upload',
  },
  {
    /**
     * 传入文件编号，返回文件url
     * http://10.222.95.129/#/view/bXKV1M87
     */
    name: 'getImageUrl',
    method: 'get',
    url: data => getRestUrl('/api/page/file/manage/locate/{fileno}', data),
  },
  {
    /**
     * 传入文件编号，展示图片（非接口）
     * http://10.222.95.129/#/view/bXKV1M87
     */
    name: 'getImage',
    method: 'get',
    url: data => getRestUrl('/api/page/file/manage/getimage/{fileno}', data),
  },
  {
    /**
     * 传入文件编号，下载图片（非接口）
     * http://10.222.95.129/#/view/ez7q3M8a
     */
    name: 'downloadImage',
    method: 'get',
    url: data => getRestUrl('/api/page/file/manage/download/{fileno}', data),
  },
  {
    /**
     * 根据code查询省市区列表
     * http://10.222.95.129/#/view/aXYw6O2r
     */
    name: 'queryAddressList',
    method: 'post',
    url: '/api/page/dictionary/address/queryAddressList',
  },
  {
    /**
     * 根据code查询省市区列表
     * http://10.222.95.129/#/view/aXYw6O2r
     */
    name: 'queryLevel4Address',
    method: 'post',
    url: '/api/page/dictionary/address/queryLevel4AddressDetail',
  },
  {
    /**
     * 查询用户额度信息
     * http://10.222.95.129/#/view/wXnp6Y29
     */
    name: 'queryUserQuotaInfo',
    method: 'post',
    url: '/api/page/quota/queryUserQuotaInfo',
  },
  {
    /**
     * 查询产品利率 url(/api/page/rate/queryProductRateInfo)
     * http://10.222.95.129/#/view/P814da2q
     */
    name: 'queryProductRateInfo',
    method: 'post',
    url: '/api/page/rate/queryProductRateInfo',
  },
  {
    /**
     * 查询企业信息
     * http://10.222.95.129/#/view/VXEZwdzv
     */
    name: 'queryCompanyManagerModuleInfo',
    method: 'post',
    url: '/api/page/companyManagerInfo/queryCompanyManagerModuleInfo',
  },
  {
    /**
     * 查询融资合同列表
     * /api/page/finance/contract/list
     * http://10.222.95.129/#/view/BXgqjD2G
     */
    name: 'queryFinanceContractList',
    method: 'post',
    url: '/api/page/finance/contract/list',
  },
  {
    /**
     * 查询菜单与产品类型
     * /api/page/userProduct/getProductMenus
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62739f8f7a82f9036757c1c3
     */
    name: 'getProductMenus',
    method: 'post',
    url: '/api/page/userProduct/getProductMenus',
  },
  {
    /**
     * 风控域-查询额度-查询用户额度信息
     * /api/page/risk/queryUserLimitInfo
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6246e4601627a00967237a3b
     */
    name: 'queryUserLimitInfo',
    method: 'post',
    url: '/api/page/risk/queryUserLimitInfo',
  },
  {
    /**
     * 查询字典列表 url(/api/page/dictionary/itemslist)
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62f47566f54b57a7351ffdce
     */
    name: 'queryDictList',
    method: 'post',
    url: '/api/page/dictionary/itemslist',
  },
  {
    /**
     * 获取产品列表 url(/api/page/product/list)
     *
     */
    name: 'queryProdctList',
    method: 'post',
    url: '/api/page/product/list',
  },
]
