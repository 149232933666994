import './assets/css/ui-cover.less'
import logo from './assets/images/logo/nav-logo.png'
import NavHeader from './components/nav/NavHeader.vue'
import NavFooter from './components/nav/NavFooter.vue'
const openIndex = () => import('./components/open/index.vue')
const openFlow = () => import('./components/open/Flow.vue')
import loanSearch from './components/loan/Search.vue'
import financeableSearch from './components/loan/FinanceSearch.vue'
import receiveableSearch from './components/loan/FinanceSearch.vue'
// import loanSearch from './components/repay/FinanceSearch.vue'
import repaySearch from './components/repay/Search.vue'
import repayFinanceableSearch from './components/repay/FinanceSearch.vue'
import protocolSearch from './components/protocol/Search.vue'
import loanDetail from './components/loan/loanDetail.vue'
import ReceivableDetail from './components/receivable/ReceivableDetail.vue'
import RepayDetail from './components/repay/RepayDetail.vue'
import enterpriseIndex from './components/enterprise/index.vue'
// import SignContract from './components/contract/SignContract.vue'
// const Home = () => import('./components/home/index.vue')
// const Main = () => import('./components/main.vue')

import MainLayout from './components/MainLayout.vue'
export default {
  // entName: '企业的采购融资',
  entLogo: logo,
  servicePhone: '400-618-3638',
  servicePhoneTag: '<a class="text-primary" href=\'tel:400-618-3638\'>400-618-3638</a>',
  bankAccountName: '测试企业的采购融资公司',
  bankAccount: '6222111119821828',
  primaryColor: '#0043FF',
  mainLayoutComponent: MainLayout, //主布局组件，仅当微前端情况下使用
  LayoutSize: 'smal',
  /**
   * 设置路由子路由，详情等页面是否子路由，入参默认设置都是一级路由，这里也可以覆盖路由对应的组件
   * @param {Object} route 路由
   * @returns Boolean
   * 覆盖路由组件
   * if(route.name==='open') {
   *    route.component = () => import('./components/Open.vue'),
   * }
   *
   */
  checkRoute: route => {
    console.log(route)
    let routes = new Set([
      'loan-detail',
      'repay-byno',
      'loan-byno',
      'confirm-detail',
      'repay-detail',
      'receivable-detail',
      'cumtomer-account-bank',
    ])
    if (route.name == 'open' || route.name == 'flow') {
      // 开通转入流程
      route.component = route.name == 'open' ? openIndex : openFlow
      // route.meta = {}
    }
    if (route.name == 'loan-detail') {
      route.component = loanDetail
    }
    if (route.name == 'receivable-detail') {
      route.component = ReceivableDetail
    }
    if (route.name == 'repay-detail') {
      route.component = RepayDetail
    }
    if (route.name == 'enterprise') {
      route.component = enterpriseIndex
    }
    // if(route)
    // if (route.name == 'loan-sign') {
    //   route.component = RepayDetail
    // }
    // if (route.name == 'main') {
    //   route.component = Main
    // }
    // if (route.name == 'home-index') {
    //   route.component = Home
    // }
    // console.log(route)
    if (routes.has(route.name)) {
      return true
    }
    return false
  },
  nav: {
    /*bgNav: '#fff', textNavColor: 'rgba(0,0,0,0.85)', bgMenu: '#fff', textMenuColor: 'rgba(0,0,0,0.85)', activeTextMenuColor: 'var(--primary-color-1)' */
    navHeader: NavHeader,
    navFooter: NavFooter,
  },
  //准入相关配置
  access: {
    //是否显示准入条件
    isAccessCondition: true,
    //实名是否显示管理员手机号
    isRealAdminPhone: true,
    //实名流程是否显示父级流程
    flowRealShowParent: false,
    //实名流程是否显示当前流程
    flowRealShowStep: true,
    //准入流程大节点扩展
    accessNodeMap: {},
    //实名流程大节点扩展
    realNodeMap: {},
    //账户流程节点扩展
    accountNodeMap: {},
  },
  //融资配置
  finance: {
    //融资失败是否可以重新进行融资
    retryWhenFailed: false,
  },
  // 适配过滤表格的宽度
  filterWidth(list) {
    list.forEach(item => {
      if (item.width) item.width = ''
    })
    return list
  },
  //扩展的组件
  extends: {
    fullWidth: true,
    //查询组件
    search: {
      financeableSearch,
      loanSearch,
      receiveableSearch,
      repaySearch,
      repayFinanceableSearch,
      protocolSearch,
    },
  },
  //列表项自定义列头
  columnFilter: {
    //可融资列表项
    financeableColumns: [
      'originNo',
      'buyerName',
      'validAmount',
      'financeRate',
      'canFinanceAmount',
      'payState',
      'createdDate',
      'operate',
    ],
    receiveableColumns: [
      'originNo',
      'buyerName',
      'validAmount',
      'financeRate',
      'canFinanceAmount',
      'state',
      'createdDate',
      'expireDate',
      'operate',
    ],
  },
  //公共模块自定义设置，如企业信息展示、账户等
  common: {
    reload: {
      async reloadPage(params) {
        if (params.to?.params?.type === 'product') {
          params.next()
        } else {
          if (params.productStatus === 'OPENED') {
            params.next()
          } else if (params.productStatus === 'OPENING') {
            if (params.to.path === '/home' || params.to.name === 'home-index') {
              // params.next({ name: 'index' })
              // console.log('/scf/open')
              window.location.href = '/scf/open'
            } else {
              params.next()
            }
          } else {
            window.location.href = '/scf/'
          }
        }
      },
    },
    enterprise: {
      //企业认证信息
      auth: {
        title: '认证信息',
        filter: ['companyName', 'uscc', 'officeAddress', 'address', 'opExpireDate', 'cellphone'],
      },
      //企业法人信息
      legal: {
        title: '法人信息',
        filter: ['legalName', 'legalCardNo', 'phone', 'legalExpireDate'],
      },
      //紧急联系人信息，中海油不需要的
      contact: {
        title: '紧急联系人信息',
        filter: ['personName', 'relationship', 'personCellphone', 'operate'],
      },
    },
    // 转换url
    convertUrl(params) {
      let linkUrl = ''
      const convert = function(url) {
        // eslint-disable-next-line no-undef
        if (__MODE__ !== 'release') {
          if (url.indexOf('/api') !== -1) {
            return `${url}`
          } else {
            return `api${url}`
          }
        } else {
          let urls = url.split('?')
          let base = urls[0]
          let params = urls[1]
          base = base.replace('/api', '')
          let code = base.replace(/\//g, '.').replace(/(^\s*)|(\s*$)/g, '')
          let search = new URLSearchParams(params)
          search.append('apiCode', `loan.yfz${code}`) // 预发gj.test //线上 loan.yfz
          return `//jnos-api-cloud.crxn.cn${base}?${search.toString()}`
        }
      }
      if (typeof params === 'object') {
        linkUrl = convert(params.url)
      } else {
        linkUrl = convert(params)
      }
      return linkUrl
    },
  },
  //覆盖接口调用，用于不同项目同一功能接口不同
  overrideMap: {
    isOpenProduct: '/api/page/yfz/access/venderInfo',
  },
  // 转换合同url
  converSignUrl(url) {
    let newUrl = url.replace(/http.*?\.(cn|com)/g, window.location.origin)
    return newUrl
  },
}

export * from './router'
