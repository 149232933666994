export default [
  {
    /**
     * 查询合同列表 (url: /api/page/contract/query/list)
     * http://10.222.95.129/#/view/32Q4rLz0
     */
    name: 'queryContractList',
    method: 'post',
    url: '/api/page/contract/query/list',
  },
  {
    /**
     * 获取签署地址 (url: /api/page/contract/fetchSignUrl)
     * http://10.222.95.129/#/view/V2JNja2y
     */
    name: 'fetchSignUrl',
    method: 'post',
    url: '/api/page/contract/fetchSignUrl',
  },
  {
    /**
     * 修改合同签署状态 (url: /api/page/contract/waitCallback)
     * http://10.222.95.129/#/view/pX9gk02r
     */
    name: 'waitCallback',
    method: 'post',
    url: '/api/page/contract/waitCallback',
  },
]
