/* eslint-disable camelcase */

import { formatDate } from '.'

/**
 * 节点状态
 */
export const flowNodeStatus = {
  NOT_START: '未开始',
  WAIT_OPERATION: '待操作',
  IN_OPERATION: '操作中',
  DONE: '已完成',
  FAILED: '失败',
}

// 担保角色
export const guarRoles = {
  LEGAL: '法定代表人',
  SPOUSE: '法定代表人配偶',
  ACTUAL_CONTROL: '实际控制人',
  ACTUAL_CONTROL_SPOUSE: '实际控制人配偶',
  NATURAL_PERSON: '其他自然人',
  OTHER: '其他主体',
}

/**
 * 实名状态
 */
export const realStatus = {
  REALED: {
    type: 'success',
    text: '已实名',
  },
  NO_REAL: {
    type: 'warning',
    text: '未实名',
  },
  REAL_EXPIRED: {
    type: 'warning',
    text: '已过期',
  },
  REALINHG: {
    type: 'warning',
    text: '实名审核中',
  },
}

/**
 * 关系
 */
export const emergencyType = {
  FATHER: '父亲',
  MOTHER: '母亲',
  SISTER: '姐妹',
  BROTHER: '兄弟',
}

/**
 * 产品开通状态
 */
export const ProductOpenStatus = {
  NOT_START: '未开通',
  IN_OPERATION: '开通中',
  DONE: '已开通',
  REJECT: '被拒绝',
  NOT_ALLOW: '不允许开通',
}

/**
 * 卡片用户状态
 */
export const repayStatus = {
  NORMAL: {
    text: '正常',
    type: 'primary',
    desc: data => {
      return `还款日：${formatDate(data.recentRepayDate)}`
    },
  },
  NO_SETTLE: {
    text: '未结清',
    type: 'primary',
    desc: data => {
      return `还款日：${formatDate(data.recentRepayDate)}`
    },
  },
  SETTLE: { text: '结清', type: 'info', desc: () => '' },
  OVER_DUE: { text: '逾期', type: 'danger', desc: () => '已逾期' },
  GRACE: {
    text: '宽限期',
    type: 'warning',
    desc: data => {
      return `已进入宽限期，${data.graceCountDownDay}日后逾期`
    },
  },
}

/**
 * 还款状态
 */
export const repayState = {
  REPAY_PROCESS: { text: '还款中', type: 'info' },
  REPAY_SUCCESS: { text: '还款成功', type: 'default' },
  REPAY_FAIL: { text: '还款失败', type: 'danger' },
  REPAYING: { text: '还款中', type: 'info' },
}

/**
 * 还款方式
 */
export const repayMode = {
  INT_AND_CAP_CLEAR: '一次性还本付息',
  INT_1_CAP_2: '按月付息，到期还本',
  INT_1_CAP_2_FIX: '按月付息，到期还本',
  EQUAL_INSTALLMENT: '等本等息',
  AVE_CAP_PLUS_INT: '等额本息',
  AVE_CAPITAL: '等额本金',
  LSBQ: '利随本清',
  BILL: '账单',
  XXHB: '先息后本',
  OTHER: '其他',
  ARJT: '按日均摊',
  DEJX: '等额减息',
}

/**
 * 还款渠道
 */
export const repayWay = {
  OFFLINE_TRANSFER: '线下电子汇款',
}

/**
 * 融资单状态
 */
export const financeState = {
  INIT: { text: '待审批', type: 'info' },
  PROCESS: { text: '待审批', type: 'info' },
  SUCCESS: { text: '融资成功', type: 'default' },
  FAIL: { text: '融资失败', type: 'danger' },
  REJECT: { text: '审核已经驳回', type: 'danger' },
}

/**
 * 融资期限类型
 */
export const financeDurationType = {
  YEAR: '年',
  QUARTER: '季度',
  MONTH: '月',
  WEEK: '周',
  DAY: '天',
}

/**
 * 放款状态
 */
export const payState = {
  UNPAY: { text: '未融资', type: 'info' },
  PROCESS: { text: '放款中', type: 'info' },
  PAID: { text: '已融资', type: 'default' },
  FAIL: { text: '融资失败', type: 'danger' },
  AUDITING: { text: '审核中', type: 'info' },
  REJECT: { text: '融资拒绝', type: 'danger' },
}

/**
 * 融资端状态
 */
export const receivableState = {
  CREATED: '订单创建',
  PART_SETTLE: '部分结算',
  CANCEL: '取消',
  SETTLE: '已经结算',
  EXPIRED: '已经过期',
}

/**
 * 确权状态
 */
export const confirmState = {
  WAITING: { text: '待确权', type: 'info' },
  FINISH: { text: '已确权', type: 'default' },
  REJECT: { text: '拒绝确权', type: 'danger' },
}
/**
 * 开类型映射
 */
export const cardTypeMap = {
  REAL: 'CARD_ACCOUNT',
}

/**
 * 结算状态
 */
export const userRepayStatus = {
  Y: '已结清',
  N: '未结清',
}
