export default [
  {
    /**
     * 法人实名
     *
     */
    name: 'initCorporationName',
    method: 'post',
    url: '/api/page/person/apply/init',
  },
  {
    /**
     * 发送邀请码
     *
     */
    name: 'sendSms',
    method: 'post',
    url: '/api/page/person/apply/send',
  },
  {
    /**
     * 确认提交
     *
     */
    name: 'yesSummit',
    method: 'post',
    url: '/api/page/person/apply/summit',
  },
  {
    /**
     * 是否走邀请码
     *
     */
    name: 'isGoInvite',
    method: 'post',
    url: '/api/page/customer/productOpen/isTrue',
  },
  {
    /**
     * 用户是否可开通此产品
     *
     */
    name: 'isOpenProduct',
    method: 'post',
    url: '/api/page/customer/productOpen/isOpen',
  },
  {
    /**
     * 非邀请码产品开通流程
     *
     */
    name: 'goNoInvite',
    method: 'post',
    url: '/api/page/customer/productOpen/flow',
  },
  // {
  //   /**
  //    * 获取企业信息
  //    *
  //    */
  //   name: 'venderInfo',
  //   method: 'post',
  //   url: '/api/page/yfz/access/venderInfo',
  // },
]
