<template>
  <div class="login-modal">
    <div>
      <tab v-model="formData.grantType" class="mb-10"></tab>
      <fin-alert v-if="error.code" :closable="false" show-icon type="error">
        <error-message :error="error" @jump="handleJump"></error-message>
      </fin-alert>
    </div>
    <fin-form class="pt-12" :hide-required-asterisk="true">
      <template v-if="formData.grantType === 'sms'">
        <fin-form-item>
          <fin-input
            v-model="formData.loginPhone"
            clearable
            maxlength="11"
            placeholder="手机号码"
            prefix-icon="fin-icon-mobile-phone"
            size="xlarge"
          ></fin-input>
        </fin-form-item>
        <fin-form-item>
          <fin-input
            v-model="formData.smsVerifyCode"
            maxlength="6"
            placeholder="输入手机验证码"
            prefix-icon="fin-icon-chat-dot-round"
            size="xlarge"
            @keydown.native="handleKeyDown"
          >
            <jdb-sms-authentication
              slot="suffix"
              size="xlarge"
              symbol="login"
              @on-click="handleSendSms"
            ></jdb-sms-authentication>
          </fin-input>
        </fin-form-item>
        <p class="text-right mt-24 mb-8">
          <fin-tooltip extend-style="width:340px" placement="top-end">
            <template #content>
              <p>1.请检查短信是否被拦截</p>
              <p>2.如确认短信未被拦截，请致电：{{ config.servicePhone }}</p>
            </template>
            <span class="cursor-pointer">收不到验证码?</span>
          </fin-tooltip>
        </p>
      </template>
      <template v-if="formData.grantType === 'password'">
        <fin-form-item>
          <fin-input
            v-model="formData.username"
            clearable
            maxlength="11"
            placeholder="手机号码"
            prefix-icon="fin-icon-mobile-phone"
            size="xlarge"
          ></fin-input>
        </fin-form-item>
        <fin-form-item>
          <fin-input
            v-model="formData.password"
            clearable
            placeholder="登录密码"
            prefix-icon="fin-icon-lock"
            size="xlarge"
            type="password"
            @keydown.native="handleKeyDown"
          ></fin-input>
        </fin-form-item>
        <a style="color: #000;" @click="handleJump({ path: '/remember' })">
          <p class="text-right mt-24 mb-8 cursor-pointer">忘记密码?</p></a
        >
      </template>
    </fin-form>
    <fin-button class="block w-full" :loading="loading" size="xlarge" type="primary" @click="handleLogin">
      <span class="mr-10">登</span>
      <span>录</span>
    </fin-button>
    <p class="mt-20 text-center cursor-pointer" @click="handleJump()">立即注册</p>
  </div>
</template>

<script lang="jsx">
import Tab from './components/Tab.vue'
import service from '@/service/index.js'
import { Regex } from '@/utils/const.js'
import { resetRouter } from '@/router';
export default {
  name: 'login-modal',
  components: {
    Tab,
    ErrorMessage: {
      props: {
        error: Object,
      },
      methods:{
        handleJumpRegister(){
          this.$emit('jump', { path: '/register' });
        },

      },
      render() {
        let { code, data, message } = this.error
        let { loginRemainTimes } = data || {}
        /**
         * ("010001","用户登录信息不存在"),
         * ("010002","用户登录信息验证失败"),
         * ("010003","登录账户锁定中"),
         * ("010004","当前账号已存在"),
         * ("010005","登录尝试频繁"),
         */
        let msg = <p>{message}</p>
        switch (code) {
          case '010001':
            msg = (
              <p>
                您输入的手机号码尚未注册，是否<a href="javascript:;" on-click={this.handleJumpRegister.bind(this)}>立即注册</a>
              </p>
            )
            break
          case '010003':
            message = <p>登录验证次数超限，账号被锁定1小时</p>
            break
          case '010005':
            msg = (
              <p>
                登录验证失败，还有<a class="cursor-pointer">${loginRemainTimes}</a>次机会
              </p>
            )
            break
          case '000006':
            msg = (
              <p>字段验证不通过</p>
            )
            break;
          case '010002':
            msg = <p>用户登录信息验证失败</p>
            break;
          default:
            break
        }
        return msg
      },
    },
  },
  props: {
    redirectUrl: [String, Function],
  },
  watch: {
    'formData.grantType'() {
      this.error = {}
    },
    'formData.smsVerifyCode'(val, old) {
      if (val && !Regex.smsVerifyCode.test(val)) {
        this.formData.smsVerifyCode = old
      }
    },
  },
  data() {
    return {
      loading: false,
      error: {},
      formData: {
        clientId:'front',
        clientSecret:'123456',
        grantType: 'sms', //pwd
        username: '',
        password: '',
        loginPhone: '',
        smsVerifyCode: '',
        smsVerifyCodeId: '',
      },
      rules: {
        password: {
          username: [
            { required: true, message: '请输入有效11位手机号' },
            {
              validator: value => {
                if (!Regex.mobile.test(value)) {
                  return new Error('请输入有效11位手机号')
                }
                return true
              },
            },
          ],
          password: [{ required: true, message: '请输入登录密码' }],
        },
        sms: {
          loginPhone: [
            { required: true, message: '请输入有效11位手机号' },
            {
              validator: value => {
                if (!Regex.mobile.test(value)) {
                  return new Error('请输入有效11位手机号')
                }
                return true
              },
            },
          ],
          smsVerifyCodeId: [{ required: true, message: '请先获取手机验证码' }],
          smsVerifyCode: [{ required: true, message: '请输入手机验证码' }],
        },
      },
    }
  },
  methods: {
    handleKeyDown(evt){
      if (evt.keyCode == 13)
      {
        evt.returnValue=false
        evt.cancel = true
        this.handleLogin()
      }
    },
    async handleLogin() {
      let data = { grantType: this.formData.grantType }
      if (this.checkForm(data)) {
        this.error = {}
        this.loading = true
        try {
          await service.doLogin({
            ...data,
            clientId:'front',
            clientSecret:'123456'
          }).executeSerial()
          let user = await service.queryLoginUserInfo().executeSerial()
          resetRouter()
          this.$store.dispatch('user/updateUser', { ...user, login: user.login })
          this.$emit('close')
          if (this.redirectUrl) {
            if (typeof this.redirectUrl === 'function') {
              this.redirectUrl()
            } else {
              this.$router.replace({ path: this.redirectUrl })
            }
          }else{
            this.$router.push({ name: 'home-index' })
          }
        } catch (error) {
          this.setError(error)
        } finally {
          this.loading = false
        }
      }
    },
    setError(error) {
      this.error = error
    },
    handleJump(path={ path: '/register' }) {
      this.$router.replace(path)
      this.$emit('close')
    },
    handleSendSms() {
      return new Promise((resolve, reject) => {
        if (!Regex.mobile.test(this.formData.loginPhone)) {
          this.setError({ code: '0', message: '请输入有效11位手机号' })
          reject('请输入有效11位手机号')
          return
        }
        this.error = {}
        service
          .authenticationSms({
            loginPhone: this.formData.loginPhone,
          })
          .executeSerial()
          .then(data => {
            this.formData.smsVerifyCodeId = data
            resolve()
          })
          .catch(error => {
            this.setError(error)
            reject()
          })
      })
    },
    checkForm(data) {
      let rules = this.rules[this.formData.grantType]
      for (let m of Object.keys(rules)) {
        for (let n of rules[m]) {
          if (n.required) {
            if (!this.formData[m]) {
              this.error = { code: '0', message: n.message }
              return false
            }
          } else if (n.validator) {
            if (!n.validator.call(this, this.formData[m])) {
              this.error = { code: '0', message: n.message }
              return false
            }
          }
        }
        data[m] = this.formData[m]
      }
      return true
    },
  },
  mounted() {
    console.log(this)
  }
}
</script>

<style lang="less">
.login-modal {
  padding: 8px 8px 4px;
  .fin-alert {
    padding: 4px 12px;
  }
  .fin-input--xlarge {
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 48px;
      top: 14px;
      width: 1px;
      height: 20px;
      background: #d9d9d9;
    }
    height: 48px;
    .fin-input__inner {
      height: 48px;
      padding-left: 60px;
      line-height: 48px;
    }
    .fin-input__icon {
      font-size: 24px;
      color: rgba(#000, 0.25);
    }
    .fin-input__prefix {
      padding-top: 3px;
      left: 12px;
    }
    .fin-input__suffix {
      .fin-input__icon {
        font-size: 20px;
      }
    }
  }
  .jdb-sms-authentication {
    font-size: 14px;
  }
}
</style>
