<template>
  <div class="custom-enterprise-card">
    <div v-if="config.common.enterprise.auth" class="public-card">
      <jdb-title-container class="custom-enterprise-card-son" size="small" :title="title.auth">
        <jdb-info-display :data="enterpriseData" label-align="left" :labels="authLabels" />
      </jdb-title-container>
    </div>
    <div v-if="config.common.enterprise.legal" class="public-card">
      <jdb-title-container class="custom-enterprise-card-son" size="small" :title="title.legal">
        <jdb-info-display :data="enterpriseData" label-align="left" :labels="legalLabels" />
      </jdb-title-container>
    </div>
    <div v-if="config.common.enterprise.contact" class="public-card">
      <jdb-title-container class="custom-enterprise-card-son" size="small" :title="title.contact">
        <template #header-extra>
          <fin-button @click="add">新增</fin-button>
        </template>
        <jdb-table :columns="columns" :list="dataList" :pagination="null"> </jdb-table>
      </jdb-title-container>
    </div>
  </div>
</template>

<script lang="jsx">
import Table from '@/views/components/Table.vue'
import service from '@/service'
import AddForm from '@/views/enterprise/components/AddForm.vue'
export default {
  name: 'customer-enterprise',
  components: {
    JdbTable: Table,
  },
  data() {
    return {
      dialogVisible: false,
      enterpriseData: {},
      authenticationLabels: [
        //认证信息
        {
          name: 'companyName',
          label: '企业名称',
          formatter: data => {
            return (
              <p>
                <span>{data.companyName}</span>
                <fin-tag class="ml-10" type="success" effect="dark">
                  已认证
                </fin-tag>
              </p>
            )
          },
        },
        {
          name: 'uscc',
          label: '统一社会信用代码',
        },
        {
          name: 'officeAddress',
          label: '企业注册地址',
        },
        {
          name: 'address',
          label: '经营地址',
        },
        {
          name: 'opExpireDate',
          label: '证件有效截至日期',
        },
        {
          name: 'cellphone',
          label: '企业电话',
        },
        {
          name: 'scope',
          label: '经营范围',
        },
      ],
      methodPersonLabels: [
        //法人信息
        { name: 'legalName', label: '法人姓名' },
        { name: 'legalCardNo', label: '法人身份证' },
        { name: 'phone', label: '法人手机号' },
        { name: 'legalExpireDate', label: '证件有效截止日期' },
      ],
      columns: [
        //紧急联系人
        {
          name: '姓名',
          dataIndex: 'personName',
        },
        {
          name: '类型',
          dataIndex: 'relationship',
          render: ({ row }) => this.enumFilter(row.relationship, 'relationship'),
        },
        {
          name: '手机号',
          dataIndex: 'personCellphone',
        },
        {
          name: '操作',
          dataIndex: 'operate',
          render: ({ row, $index }) => (
            <div>
              <fin-button class='custon-btn-color' type="text" on-click={this.edit.bind(this, { row, $index })}>
                修改
              </fin-button>
              <fin-button class='custon-btn-color' type="text" on-click={this.delete.bind(this, row)}>
                删除
              </fin-button>
            </div>
          ),
        },
      ],
      dataList: [],
    }
  },
  computed:{
    authLabels(){
      return this.authenticationLabels.filter(m=>{
        let filter = this.config.common.enterprise.auth?.filter
        if(filter){
          return filter.includes(m.name)
        }
        return true
      })
    },
    legalLabels(){
      return this.methodPersonLabels.filter(m=>{
        let filter = this.config.common.enterprise.legal?.filter
        if(filter){
          return filter.includes(m.name)
        }
        return true
      })
    },
    contactColumns(){
      return this.methodPersonLabels.filter(m=>{
        let filter = this.config.common.enterprise.contact?.filter
        if(filter){
          return filter.includes(m.dataIndex)
        }
        return true
      })
    },
    title(){
      return {
        auth: this.config.common.enterprise.auth?.title || '认证信息',
        legal: this.config.common.enterprise.legal?.title || '法人信息',
        contact: this.config.common.enterprise.contact?.title || '紧急联系人信息'
      }
    }
  },
  methods: {
    //紧急人列表查询
    async queryList() {
      let list = await service.queryEmergencyContact({ userNo: this.$store.getters.userInfo.userNo }).executeSerial()
      this.dataList = list
      console.log(list)
    },
    // 新增紧急联系人
    async addEmergencyContact(data, done) {
      let option = Object.assign(data, { userNo: this.$store.getters.userInfo.userNo })
      await service.addEmergencyContact(option).executeSerial()
      this.$message({
        message: '新增成功',
        type: 'success',
      })
      this.queryList()
      done()
    },
    //修改紧急联系人
    async updataEmergencyContact(data, done) {
      let option = Object.assign(data, { userNo: this.$store.getters.userInfo.userNo })
      await service.updataEmergencyContact(option).executeSerial()
      this.$message({
        message: '更新成功',
        type: 'success',
      })
      this.queryList()
      done()
    },

    add() {
      this.openDialog(<AddForm title="请输入紧急联系人信息" on-submit={(data, done)=>{this.addEmergencyContact(data, done)}}></AddForm>, {width: '480px', type:'primary', contentTitle:'新增紧急联系人', customClass: 'custom-dialog-box'})
    },
    edit(obj) {
      this.openDialog(<AddForm title="请输入紧急联系人信息" origin={obj.row} on-submit={(data, done)=>{this.updataEmergencyContact(data, done)}}></AddForm>, {width: '480px', type:'primary', contentTitle:'修改紧急联系人', customClass: 'custom-dialog-box'})
    },
    //删除
    delete(row) {
      console.log(row)
      this.$confirm('请确认是否删除紧急联系人信息？', {
        contentTitle: '温馨提示',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await service.deleteEmergencyContact(row)
          await this.queryList()
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {})
    },
    async queryEnterpriseInfo() {
      let data = await service
        .queryCompanyManagerModuleInfo({
          moduleType: 'COMPANY_INFO',
          productCode: this.productCode,
        })
        .executeSerial()
      this.enterpriseData = data
      console.log(data, '企业信息')
    },
  },
  mounted() {
    this.queryEnterpriseInfo()
    if(this.config.common.enterprise.contact){
      this.queryList()
    }
  },
}
</script>
