import cssVars from 'css-vars-ponyfill'
import getThemeVars from '../assets/theme/color'
import tinycolor from '@/assets/theme/color/tinycolor.js'
import dayjs from 'dayjs'
import store from '@/store'
import axios from 'axios'
const modulesFiles = import.meta.globEager('../assets/theme/*.js')

const modules = Object.keys(modulesFiles).reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^..\/assets\/theme\/(.*)\.\w+$/, '$1')
  const value = modulesFiles[modulePath]
  modules[moduleName] = value.default
  return modules
}, {})

export function loadScript(url) {
  let script = document.createElement('script')
  let promise = new Promise((resolve, reject) => {
    script.onerror = reject
    script.onload = script.onreadystatechange = function(e) {
      if (!e.readyState || e.readyState === 'loaded' || e.readyState === 'complete') {
        script.onload = script.onreadystatechange = null
        resolve()
        document.head.removeChild(script)
      }
    }

    script.src = url
    document.head.appendChild(script)
  })
  return promise
}

export function formatRate(rate, bit = 2) {
  return `${formatCurrency(rate * 100, bit).replace(',', '')}%`
}

/*
日期格式化
*/
export function formatDate(time, format = 'YYYY-MM-DD', offset = null) {
  if (!time) {
    return '--'
  }
  let jsTime = dayjs(time)
  if (offset) {
    jsTime = jsTime.add(offset.num, offset.type)
  }
  return jsTime.format(format)
}

/*
金额格式化
    */
export function formatCurrency(s, bit = 2) {
  if (typeof s != 'number' && !s) return ''
  s = String(s)
  if (!s.replace(/^\s+|\s+$/g, '')) return '0.00'
  let bitTxt = ''
  if (!/^(-?\d+)(\.\d*)?$/.test(s)) {
    return '-'
  }

  let sign = ''
  s = Number(s)
  if (s < 0) {
    sign = '-'
  } else {
    sign = ''
  }
  s = Math.abs(s)
  if (/^\d+$/.test(s)) {
    for (let i = 0; i < bit; i++) {
      bitTxt += '0'
    }
    if (bitTxt === '') return sign + (s + '').replace(/\B(?=(\d{3})+$)/g, ',')
    return sign + (s + '').replace(/\B(?=(\d{3})+$)/g, ',') + '.' + bitTxt
  }
  if (/^(\d+)\.(\d+)$/.test(s)) {
    s = s + '0'
    var v = s.split('.')
    var f = (v[0] + '').replace(/\B(?=(\d{3})+$)/g, ',')
    var h = v[1].substring(0, bit)
    if (h === '') return sign + f
    return sign + f + '.' + h
  }
}

export function formatMask(val, visible) {
  if (!val) return ''
  return getMaskedString(/([\d]{6})([\d]*)([\d]{4})/, val, visible ? null : '*', 1, ' ')
}

// eslint-disable-next-line no-unused-vars
export function getMaskedString(pattern, str, mask = '*', index = 0, split = '') {
  // eslint-disable-next-line no-unused-vars
  function replacer(match, p1, p2, p3, offset, string) {
    let newArr = Array.prototype.slice.apply(arguments, [1, -2])
    if (mask) {
      newArr[index] = ''.padStart(newArr[index].length, mask)
    }
    return newArr.join(split)
  }
  var newString = str.replace(pattern, replacer)

  return newString
}

export function formatPhone(phone) {
  if (!phone) {
    return ''
  }
  return getMaskedString(/([\d]{3})([\d]{4})([\d]{4})/, phone, '*', 1)
}

/**
 * 判断当前对象是否是指定类型
 * @param {Object} obj 类型检测对象
 * @param {String} type 类型断言
 * @returns 返回结果
 */

export function isType(obj, type) {
  return (
    Object.prototype.toString
      .call(obj)
      .slice(8, -1)
      .toLowerCase() === type.toLowerCase()
  )
}

export function getProductCode() {
  return getStorage('stdProductCode')
}
export function setProductCode(productCode) {
  return setStorage('stdProductCode', productCode)
}

export function getProductType() {
  return getStorage('productType')
}

export function setProductType(productType) {
  return setStorage('productType', productType)
}

/*
  存储localStorage
 */
export function setStorage(name, obj) {
  let str = JSON.stringify(obj)
  localStorage[name] = str
}
/**
  获取localStorage
 */
export function getStorage(name) {
  if (localStorage[name]) {
    return JSON.parse(localStorage[name])
  }
}
/**
  获取localStorage，并清除
 */
export function getStorageOnce(name) {
  if (localStorage[name]) {
    let val = localStorage[name]
    removeStorage(name)
    return JSON.parse(val)
  }
}
/*
  清除localStorage
 */
export function removeStorage(name) {
  localStorage.removeItem(name)
}

// 获取参数
export function getQueryParams(search) {
  search = search || window.location.search
  let params = {}
  let query
  const pos = search.indexOf('?')

  if (pos !== -1) {
    query = search.slice(pos + 1)
    if (query.length > 0) {
      params = {}
      query = query.split('&')
      query.forEach(function(param) {
        var tempParam = param.split('=')
        params[tempParam[0]] = decodeURIComponent(param.substring(param.indexOf('=') + 1, param.length))
      })
    }
  }
  return params
}

export function getColorVars(theme) {
  let themeVars = null

  if (new tinycolor(theme).isValid()) {
    themeVars = getThemeVars({
      'primary-color': theme,
    })
  } else {
    let module = modules[theme]
    if (module) {
      themeVars = module.vars
    } else {
      theme = 'default'
      themeVars = modules[theme].vars
    }
  }

  return themeVars
}

export function changeTheme(theme) {
  const themeVars = getColorVars(theme)
  const vars = Object.keys(themeVars)
    .map(key => `--${key}:${themeVars[key]}`)
    .join(';')
  document.documentElement.setAttribute('style', vars)
  cssVars({
    variables: themeVars,
  })
}

const styleTest = document.createElement('div').style

const render = {
  webkit: 'webkitTransform',
  ms: 'msTransform',
  Moz: 'MozTransform',
  O: 'OTransform',
  standard: 'transform',
}

const getPrefix = (() => {
  for (let key in render) {
    if (styleTest[render[key]] !== undefined) {
      return key
    }
  }
})()

// 节流
export function throttle(fn, interval, options = { leading: true, trailing: false }) {
  // * 记录上一次的开始时间
  let lastTime = 0
  // * 将是否第一次触发和最后一次触发取出来
  const { leading, trailing, resultCallback } = options

  // * 最后一次执行的定时器
  let timer = null

  // * 事件触发时，真正执行的函数
  const _throttle = function(...args) {
    return new Promise(resolve => {
      // * 获取当前事件触发时的时间  getTime 获取的是时间戳
      const nowTime = new Date().getTime()
      // * 第一次不触发的时候，将lastTime设置为nowTime
      if (!lastTime && !leading) lastTime = nowTime
      // * 使用当前触发的时间和上一次的开始时间、时间间隔，计算出还剩多长时间触发函数。
      const remainTime = interval - (nowTime - lastTime)
      if (remainTime <= 0) {
        // * 真正触发函数
        const result = fn.apply(this, args)
        if (resultCallback) resultCallback(result)
        resolve(result)
        // * 保留上次触发的时间
        lastTime = nowTime

        // * 清空timer
        if (timer) {
          clearTimeout(timer)
          timer = null
          return
        }
      }
      if (trailing && !timer) {
        //* 最后一次执行
        timer = setTimeout(() => {
          timer = null
          lastTime = !leading ? 0 : new Date().getTime()
          const result = fn.apply(this, args)
          if (resultCallback) resultCallback(result)
          resolve(result)
        }, remainTime)
      }
    })
  }

  _throttle.cancel = function() {
    if (timer) clearTimeout(timer)
    timer = null
    lastTime = 0
  }

  return _throttle
}

/**
 * 防抖
 * @param {Function} fn 要执行的方法
 * @param {Number} delay 延迟时间
 * @param {Boolean} immediate 是否立即执行
 * @param {Function} resultCallback 结果回调
 * @returns 可执行的方法
 */
export function debounce(fn, delay, immediate = false, resultCallback) {
  // * 定义了一个定时器，保存上一次的定时器
  let timer = null
  let isInvoke = false
  // * 真正执行的函数
  const _debounce = function(...args) {
    // * 如果上一次已经设置了定时器，就将上一次的定时器取消，
    if (timer) clearTimeout(timer)

    // * 判断是否需要 立即执行
    if (immediate && !isInvoke) {
      const result = fn.apply(this, args)
      if (resultCallback) resultCallback(result)
      isInvoke = true
    } else {
      timer = setTimeout(() => {
        // * 外部传入要真正执行的函数
        const result = fn.apply(this, args)
        if (resultCallback) resultCallback(result)
        isInvoke = false
      }, delay)
    }
  }

  // * 封装取消功能
  _debounce.cancel = function() {
    if (timer) clearInterval(timer)
    timer = null
    isInvoke = false
  }

  return _debounce
}
/**
 * 设置动态样式
 */
export function stylePrefix(style) {
  if (getPrefix === 'standard') {
    return style
  }
  return getPrefix + style.charAt(0).toUpperCase() + style.substr(1)
}

/*
根据字典的code即value，以字典key即enumType，this.enumData[key]-->[{}]，来获取字典的name
*/
export function enumFilter(value, enumType) {
  if (Array.isArray(value)) {
    const result = value.reduce(
      (acc, cur) =>
        acc +
        ((
          (store.getters.enumData[enumType] &&
            store.getters.enumData[enumType].find(item => String(item.itemCode) === String(cur))) ||
          {}
        ).itemName || '') +
        '、',
      '',
    )
    return result.substring(0, result.length - 1)
  } else {
    return (
      (store.getters.enumData[enumType] &&
        store.getters.enumData[enumType].find(item => String(item.itemCode) === String(value))) ||
      {}
    ).itemName
  }
}
/**
 * @describe 脱敏校验 如果原始值和新值一致 则不校验 反之则校验
 * @param {value} oldvalue 原始值
 * @param {value} newvalue 新值
 * @returns 返回true或false
 */
export const isIntercept = (oldvalue, newvalue) => oldvalue === newvalue

/**
 * @fileName 文件名
 * @fileName 入参 this.formData={}
 * @url eq:${baseURL}api/page/file/manage/download/multi
 */

export function download(url, data, fileName, method = 'get') {
  axios({
    method,
    url,
    data,
    responseType: 'blob',
    withCredentials: true,
  }).then(response => {
    const dataUrl = window.URL.createObjectURL(response.data)
    fileName = response.headers['extra-filename']
      ? decodeURIComponent(response.headers['extra-filename'])
      : dayjs().format('yyyyMMddHHmmss')
    if (response.headers['content-type'].includes('application/octet-stream')) {
      fileName += '.zip'
    }
    if (response.headers['content-type'].includes('application/vnd.ms-excel')) {
      fileName += '.xlsx'
    }
    const link = document.createElement('a')
    link.href = dataUrl

    link.setAttribute('download', fileName)
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(dataUrl)
    document.body.removeChild(link)
  })
}

// 格式化银行卡号
export function formatAccount(account) {
  if (account === '' || !account) return '--'
  return account.replace(/\s/g, '').replace(/(.{4})/g, '$1 ')
}

/**
 * 滚动到头部
 */
export function scrollToStart() {
  let topTarget = document.getElementsByClassName('body-top')
  topTarget[0]?.scrollIntoView({
    block: 'start',
    behavior: 'smooth',
  })
}

/**
 * 滚动到错误的地方
 */
export function scrollToError() {
  let topTarget = document.getElementsByClassName('is-error')
  topTarget[0]?.scrollIntoView({
    block: 'start',
    behavior: 'smooth',
  })
}
