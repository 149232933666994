const state = {
  menu: [],
  productInfo: {},
  config: {},
}

const mutations = {
  SET_MENU: (state, menu) => {
    state.menu = menu
  },
  SET_PRODUCT_INFO: (state, productInfo) => {
    state.productInfo = productInfo
  },
  SET_CONFIG: (state, config) => {
    state.config = config
  },
}

const actions = {
  setProductInfo({ commit }, productInfo) {
    commit('SET_PRODUCT_INFO', productInfo)
  },
  setMenu({ commit }, menu) {
    commit('SET_MENU', menu)
  },
  setConfig({ commit }, config) {
    commit('SET_CONFIG', config)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
