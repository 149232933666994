import APIServiceCaller, { AjaxFactory } from 'api-service-caller'
import ajaxRunable from './api'
import config from './config'

let factory = new AjaxFactory()

factory.setRunable(new ajaxRunable())
let service = new APIServiceCaller(config, factory)

export default service
