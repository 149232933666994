const getters = {
  userInfo: state => state.user.userInfo,
  menu: state => state.system.menu,
  productInfo: state => state.system.productInfo,
  config: state => state.system.config,
  // 字典数据和对象
  enumMap: state => state.enum.enumMap,
  enumData: state => state.enum.enumData,
  getbaseEnterprise: state => state.user.baseEnterprise,
  allProducts: state => state.product.allProducts,
  productCode: state => state.product.currentProductCode,
  outer: state => state.outer.outer,
}
export default getters
