export default [
  {
    /**
     * 融资端—首页—我的账户 url(/api/page/account/query/queryAccountList)
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6278bedb1627a00967237ec6
     */
    name: 'queryAccountList',
    method: 'post',
    url: '/api/page/account/query/queryAccountList',
  },
  {
    /**
     * 融资端—首页—我的账户 新增
     */
    name: 'saveAccount',
    method: 'post',
    url: '/api/page/account/query/saveAccount',
  },
  {
    /**
     * 融资端—首页—我的账户 更新
     */
    name: 'updateAccount',
    method: 'post',
    url: '/api/page/account/query/updateAccount',
  },
  {
    /**
     * 融资端—首页—我的账户 详情
     */
    name: 'queryAccountDetail',
    method: 'post',
    url: '/api/page/account/query/queryAccount',
  },
  {
    /**
     * 融资端—客户中心-首页企业信息
     */
    name: 'queryCustomer',
    method: 'post',
    url: '/api/page/realname/apply/queryCustomer',
  },
  {
    /**
     * 融资端—客户中心-账户管理删除
     */
    name: 'deleteAccount',
    method: 'post',
    url: '/api/page/account/query/deleteAccount',
  },
  {
    /**
     * 根据code查询省市区列表
     * http://10.222.95.129/#/view/aXYw6O2r
     */
    name: 'queryAddress',
    method: 'post',
    url: '/api/page/realname/bank/province',
  },
  {
    /**
     * 查询订单编号
     */
    name: 'applyPurchase',
    method: 'post',
    url: '/api/page/finance/apply/purchase',
  },
  {
    /**
     * 账号中心-获取账户列表信息
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=63280dc079621f424e8aeae7
     */

    name: 'queryAccountListEx',
    method: 'post',
    url: '/api/page/account/accountList',
  },
  {
    /**
     * 账号中心-获取账户列表信息
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=63280dc079621f424e8aeae7
     */

    name: 'queryTradeRecord',
    method: 'post',
    url: '/api/page/account/tradeRecords',
  },
]
