/**
 * 登录相关
 */
export default [
  {
    /**
     * 登录
     * http://10.222.95.129/#/view/jP81qB8q
     */
    name: 'doLogin',
    method: 'post',
    url: '/api/oauth/applyToken',
  },
  {
    /**
     * 新登录发送短信
     *
     */
    name: 'authenticationSms',
    method: 'post',
    url: '/api/page/login/noAuth/authenticationSms',
  },
  {
    /**
     * 登录发送短信
     * http://10.222.95.129/#/view/L42GdQ8W
     */
    name: 'loginSms',
    method: 'post',
    url: '/api/page/login/noAuth/loginSms',
  },
  {
    /**
     * 登录发送短信
     * http://10.222.95.129/#/view/r2jZ1M2G
     */
    name: 'queryLoginUserInfo',
    method: 'post',
    url: '/api/oauth/noAuth/queryAuthInfo',
  },
  {
    /**
     * 功能描述: 重置短信密码
     * http://10.222.160.160:8080/#/view/Q2V7518e
     */
    name: 'resetLoginPassword',
    method: 'post',
    url: '/api/page/login/noAuth/resetLoginPassword',
  },
  {
    /**
     * 查询登录用户信息
     * http://10.222.160.160:8080/#/view/BXgY5W2G
     */
    name: 'queryLoginIno',
    method: 'post',
    url: '/api/page/login/noAuth/queryLoginIno',
  },
  {
    /**
     * 获取重置token
     * http://10.222.160.160:8080/#/view/aXYV5xzr
     */
    name: 'resetAuthentication',
    method: 'post',
    url: '/api/page/login/noAuth/resetAuthentication',
  },
  {
    /**
     * 重置短信密码
     * http://10.222.160.160:8080/#/view/Q2V7518e
     */
    name: 'resetLoginPassword',
    method: 'post',
    url: '/api/page/login/noAuth/resetLoginPassword',
  },
  {
    /**
     * 退出
     * http://10.222.160.160:8080/#/view/7zLQom2Q
     */
    name: 'loginOut',
    method: 'post',
    url: '/api/oauth/loginOut',
  },
  {
    /**
     * 退出
     * http://10.222.160.160:8080/#/view/7zLQom2Q
     */
    name: 'loginVerification',
    method: 'post',
    url: '/api/page/login/loginVerification',
  },
]
