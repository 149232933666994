/**
 * 信用流转
 */
export default [
  {
    /**
     * 人员管理启用和禁用
     * http://10.222.95.129/#/view/q2RE9Bzg
     */
    name: 'enableDisbleOperator',
    method: 'post',
    url: '/api/page/operator/enable',
  },
  {
    /**
     * 人员管理新增人员
     * http://10.222.95.129/#/view/q2RE9Bzg
     */
    name: 'addOperator',
    method: 'post',
    url: '/api/page/operator/add',
  },
  {
    /**
     * 人员管理修改人员
     * http://10.222.95.129/#/view/q2RE9Bzg
     */
    name: 'updateOperator',
    method: 'post',
    url: '/api/page/operator/up',
  },
  {
    /**
     * 人员管理人员列表
     * http://10.222.95.129/#/view/q2RE9Bzg
     */
    name: 'queryOperatorPagelist',
    method: 'post',
    url: '/api/page/operator/pagelist',
  },
  {
    /**
     * 人员管理角色列表
     * http://10.222.95.129/#/view/q2RE9Bzg
     */
    name: 'operatorRolesList',
    method: 'post',
    url: '/api/page/operator/roles',
  },
  {
    /**
     * 资金额度汇总
     * http://10.222.95.129/#/view/q2RE9Bzg
     */
    name: 'queryCreditQuota',
    method: 'post',
    url: '/api/page/creditQuota/totalAmt',
  },
  {
    /**
     * 额度分页查询
     * http://10.222.95.129/#/view/q2RE9Bzg
     */
    name: 'queryCreditQuotaList',
    method: 'post',
    url: '/api/page/creditQuota/list',
  },
  {
    /**
     * 开单记录分页查询
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62fbadb9855696ad35fc3e77&sso_token=a2WDkgx6hJRQ430Y9bji1AdUlmOLyBoq5C8
     */
    name: 'queryOpenRecordList',
    method: 'post',
    url: '/api/page/xd/openRecord/pageList',
  },
  {
    /**
     * 我的信单分页列表（供应商）
     *http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62fc9f44f54b57a7351fff83
     */
    name: 'queryMyXdList',
    method: 'post',
    url: '/api/page/xd/myXdList',
  },
  {
    /**
     * 可使用信单分页列表（供应商）
     *http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=6303591ff54b57a7352001b6
     */
    name: 'queryAvailableXdList',
    method: 'post',
    url: '/api/page/xd/availableTicket',
  },
  {
    /**
     * 我的信单我的账户
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62ff30eeaf50ba51ae0c991e
     */
    name: 'queryAccount',
    method: 'post',
    url: '/api/page/xd/receipt/account',
  },
  {
    /**
     * 我的信单顶部额度汇总（供应商）
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=630339b7f54b57a735200165
     */
    name: 'queryMyOrderQuota',
    method: 'post',
    url: '/api/page/xd/myTicket/topAmt',
  },
  {
    /**
     * 开单详情-基本信息
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62fcdef7855696ad35fc3ee1
     */
    name: 'queryBaseInfo',
    method: 'post',
    url: '/api/page/xd/openRecord/detail/baseInfo',
  },
  {
    /**
     * 开单详情-发票分页列表
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62fce11e855696ad35fc3ee3
     */
    name: 'queryInvoicePageList',
    method: 'post',
    url: '/api/page/xd/bgInfo/detail/invoicePageList',
  },
  {
    /**
     * 开单详情-合同列表
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62fce2dff54b57a7351fff9f
     */
    name: 'queryXDContractList',
    method: 'post',
    url: '/api/page/xd/bgInfo/detail/contractList',
  },
  {
    /**
     * 开单详情-其他资料
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62ff52ecaf50ba51ae0c994c
     */
    name: 'queryOtherData',
    method: 'post',
    url: '/api/page/xd/bgInfo/detail/otherData',
  },
  {
    /**
     * 开单详情-凭证列表
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=63037b15f54b57a7352001c4
     */
    name: 'queryVoucherList',
    method: 'post',
    url: '/api/page/xd/bgInfo/detail/voucherList',
  },
  {
    /**
     * 开单复核-分页列表
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=6306070bf54b57a73520032f&sso_token=68fUMjBm5QRYkZyCXWIacJt2gpqOLsvz39e
     */
    name: 'queryReviewList',
    method: 'post',
    url: '/api/page/xd/recheck/pageList',
  },
  {
    /**
     * 供应商-完善贸易资料Tab
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=63060eabf54b57a735200333
     */
    name: 'queryPerfectTradeInfoList',
    method: 'post',
    url: '/api/page/xd/supplierTab/perfectingTradeInfo',
  },
  {
    /**
     * 供应商-签收信单Tab
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=63060f63af50ba51ae0c9bac
     */
    name: 'querySignXDList',
    method: 'post',
    url: '/api/page/xd/supplierTab/signXD',
  },
  {
    /**
     * 开单详情-流程
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=63073b55f54b57a7352003ba
     */
    name: 'queryXDProcessInfo',
    method: 'post',
    url: '/api/page/xd/openRecord/detail/process',
  },
  {
    /**
     * 开单详情-流程-转让
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=63073b55f54b57a7352003ba
     */
    name: 'queryTransferXDProcessInfo',
    method: 'post',
    url: '/api/page/xd/transferRecord/detail/process',
  },
  {
    /**
     * 开单复核-拒绝
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=63073d0bf54b57a7352003c3
     */
    name: 'rejectXDReview',
    method: 'post',
    url: '/api/page/xd/recheck/reject',
  },
  {
    /**
     * 查询合同凭证信息
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=6307709daf50ba51ae0c9c3a
     */
    name: 'queryXDContractInfo',
    method: 'post',
    url: '/api/page/xd/contract/queryContract',
  },
  {
    /**
     * 供应商-转让复核Tab
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=6307754faf50ba51ae0c9c3d
     */
    name: 'queryXDTransferRecheckList',
    method: 'post',
    url: '/api/page/xd/supplierTab/transferRecheckTabList',
  },
  {
    /**
     * 转让信单记录-分页查询
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=63088cb3af50ba51ae0c9cc1
     */
    name: 'queryXDTransferRecordList',
    method: 'post',
    url: '/api/page/xd/transferRecord/pageList',
  },
  {
    /**
     * 我的信单详情-基本信息
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=6308bb0daf50ba51ae0c9cd1
     */
    name: 'queryMyxdDetailBaseInfo',
    method: 'post',
    url: '/api/page/xd/myTicket/detailBaseInfo',
  },
  {
    /**
     * 复核签收待回调
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=63072773af50ba51ae0c9bef
     */
    name: 'waitXDCallback',
    method: 'post',
    url: '/api/page/xd/contract/waitCallback',
  },
  {
    /**
     * 查询信单当前状态
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=63088660f54b57a735200781
     */
    name: 'getXDCurrentState',
    method: 'post',
    url: '/api/page/xd/contract/getCurrentState',
  },
  {
    /**
     * 查询信单开立配置 url(/api/page/xd/openApply/query/config)
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62fde61f855696ad35fc3f32
     */
    name: 'queryCreditConfig',
    method: 'post',
    url: '/api/page/xd/openApply/query/config',
  },
  {
    /**
     * 查询已有发票 url(/api/page/xd/invoice/have)
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62fe4671855696ad35fc4138
     */
    name: 'queryHaveInvoiceList',
    method: 'post',
    url: '/api/page/xd/invoice/have',
  },
  {
    /**
     * 人工添加发票 url(/api/page/xd/invoice/have)
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62fe4671855696ad35fc4139
     */
    name: 'manualAddInvoice',
    method: 'post',
    url: '/api/page/xd/invoice/manual',
  },
  {
    /**
     * 信单接收方列表查询 url(/api/page/xd/openApply/query/recipientList)
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62fde53df54b57a7351fffdc
     */
    name: 'queryRecieveCustomers',
    method: 'post',
    url: '/api/page/xd/openApply/query/recipientList',
  },
  {
    /**
     * 查询账户 url(/api/page/xd/openApply/query/recipientAcc)
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62fde5b0855696ad35fc3f30
     */
    name: 'queryRecieveReceiptAcc',
    method: 'post',
    url: '/api/page/xd/openApply/query/recipientAcc',
  },
  {
    /**
     * 信单申请提交 url(/api/page/xd/openApply/apply)
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62fde668855696ad35fc3f34
     */
    name: 'submitOpenApply',
    method: 'post',
    url: '/api/page/xd/openApply/apply',
  },
  {
    /**
     * 发票修改接口 url(/api/page/xd/openApply/apply)
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=63049998f54b57a735200254
     */
    name: 'updateInvoice',
    method: 'post',
    url: '/api/page/xd/invoice/up',
  },
  {
    /**
     * 批量发票验真接口 url(/api/page/xd/ocr/verify)
     * https://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62fe4671855696ad35fc413b
     */
    name: 'batchVerifyInvoice',
    method: 'post',
    url: '/api/page/xd/ocr/verify',
  },
  {
    /**
     * 查询转让信单详情-基础信息 url(/api/page/transfer/xdApply/queryDetail)
     * https://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=630375a6f54b57a7352001be
     */
    name: 'getTransferBasicDetail',
    method: 'post',
    url: '/api/page/transfer/xdApply/queryDetail',
  },
  {
    /**
     * 转让信单申请提交 url(/api/page/transfer/xdApply/transferApply)
     * https://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=630472fff54b57a735200202
     */
    name: 'submitTransferApply',
    method: 'post',
    url: '/api/page/transfer/xdApply/transferApply',
  },
  {
    /**
     * 转让信单完善贸易背景资料-转让
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=630db203af50ba51ae0c9da5
     */
    name: 'submitReceiveTransferBgInfo',
    method: 'post',
    url: '/api/page/transfer/xdApply/receiveTransferBgInfo',
  },
  {
    /**
     * 信单接收方补充背景资料 常规登录信息必传-开具
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62fde699f54b57a7351fffe1
     */
    name: 'submitReceiveUpBgInfo',
    method: 'post',
    url: '/api/page/xd/openApply/receiveUpBgInfo',
  },
  {
    /**
     * 信单接收方补充背景资料 常规登录信息必传 url(/api/page/xd/openApply/receiveUpBgInfo)
     * https://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62fde699f54b57a7351fffe1
     */
    name: 'updateMaterial',
    method: 'post',
    url: '/api/page/xd/openApply/receiveUpBgInfo',
  },
]
