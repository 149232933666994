<template>
  <div class="page-home-yfz">
    <div class="page-home-bar">
      <ul>
        <li>
          <span class="page-home-bar__label">客户角色：</span
          ><span class="page-home-bar__value">{{ real.productRoleName }}</span
          ><b class="line"></b>
        </li>
        <li>
          <span class="page-home-bar__label">业务模式：</span
          ><span class="page-home-bar__value">{{ real.productTypeName }}</span
          ><b class="line"></b>
        </li>
        <li>
          <span class="page-home-bar__label">实名状态：</span
          ><span class="page-home-bar__value">{{ authStatusMap[real.authStatus] }}</span
          ><b class="line"></b>
        </li>
        <li>
          <span class="page-home-bar__label">企业名称：</span
          ><span class="page-home-bar__value">{{ real.companyName }}</span>
        </li>
      </ul>
    </div>
    <router-view></router-view>
  </div>
</template>

<script lang="jsx">
export default {
  props: {
    real: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      authStatusMap:{
        10: '未实名',
        20: '已实名',
        30: '实名失效',
        40: '实名审核中'
      }
    }
  }
}
</script>

<style lang="less">
.page-home-yfz > .base {
  background: #fff;
  padding: 26px 24px 17px;
  border-radius: 12px;
}
.fin-dialog {
  border-radius: 12px;
}
.page-home-yfz {
  .page-home-bar {
    padding-left: 24px;
    height: 72px;
    line-height: 72px;
    background: rgba(255, 255, 255, 1);
    border-radius: 12px;
    font-size: 14px;
    margin-bottom: 16px;
    li {
      float: left;
      .page-home-bar__label {
        color: #b4b5c1;
      }
      .page-home-bar__value {
        color: rgba(57, 60, 90, 1);
      }
      .line {
        width: 1px;
        height: 13px;
        background: rgba(180, 181, 193, 1);
        display: inline-block;
        margin: 0 24px;
        font-weight: 300;
      }
    }
  }
  .fin-form-item {
    margin-bottom: 20px !important;
  }
  .fin-form-item__label {
    font-size: 12px;
  }
  .trade-loan {
    background: #fff;
    padding: 0px 24px 17px;
    border-radius: 12px;
    // .ui-tabs {
    //   margin-top: -12px;
    // }
  }
  .search {
    margin-top: 0 !important;
    margin-bottom: 20px;
    overflow: hidden;
    .fin-form-item {
      float: left;
      margin-right: 16px;
    }
    .fin-form-item__label {
      display: none;
    }
    .text-right {
      .fin-button:last-child {
        border: none;
        color: #0043ff;
        position: relative;
        left: -10px !important;
        background: none;
      }
    }
  }

  .receivable-home {
    .jdb-info-card {
      background: rgba(247, 248, 250, 1);
      width: 100%;
      border-radius: 8px;
    }
    .jdb-info-card-small,
    .jdb-info-card-account {
      border-radius: 8px;
      flex: 1;
    }
    .jdb-info-card-small {
      margin-right: 4px;
    }
    .jdb-info-card-account {
      margin-left: 4px;
    }
  }
}
</style>
