import axios from 'axios'
import store from '@/store'
import Vue from 'vue'
import { isType } from '@/utils'
import { defaultRouter } from '@/router'
const baseURL = import.meta.env.VITE_API_URL
console.log(baseURL)
const service = axios.create({
  baseURL, // url = base url + request url
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
    'Access-Control-Max-Age': '3600',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Headers': 'x-requested-with,Authorization,token, content-type',
    'Device-Type': 'PC',
  },
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
})

service.interceptors.response.use(
  function(response) {
    let { data } = response
    // 对响应数据做点什么
    if (['000000'].includes(data.code)) {
      return response
    }
    if (response.data.code === '999999') {
      throw response.data.msg
    } else if (response.data.code === '010006') {
      // 未登录
      return response
    } else if (['010001', '010002', '010003', '010004', '010005', '000006'].indexOf(response.data.code) > -1) {
      return response
    } else {
      throw response.data.msg
    }
  },
  function(error) {
    // 对响应错误做点什么
    return Promise.reject(error)
  },
)

/**
 *需要实现IRunable接口，js中没有接口，需要在class实现run方法
 */
export default class AjaxRunable {
  ajax(config) {
    let promise = service.request(config)
    return new Promise((resolve, reject) => {
      return promise
        .then(function(res) {
          let allData = res.data
          let { code, data, msg, fieldError } = allData
          if (code === '000000') {
            resolve(data)
          } else if (code === '010006') {
            store.dispatch('user/updateUser', { login: false })
            let loginUrl = store.getters.outer?.loginUrl

            if (loginUrl) {
              loginUrl += encodeURIComponent(window.location.href)
            } else if (store.getters.config?.common?.loginUrl) {
              loginUrl = store.getters.config.common.loginUrl()
            } else {
              defaultRouter.resolve({ path: '/login' })
            }

            window.location.href = loginUrl
          } else {
            //这里的code可能是
            //'010001', '010002', '010003', '010004', '010005'
            //需要在调用接口的地方单独处理
            reject({
              code,
              data,
              msg,
              fieldError,
            })
          }
        })
        .catch(function(error) {
          var msg = error || '没有连接到网络，心里空空的~'

          Vue.prototype.$message.error({
            message: msg,
          })

          reject(msg)
        })
    })
  }

  run(param) {
    let data = {
      pageReqHeader: {
        reqSource: 'PC',
        ...(window.fingerPrint || {}),
      },
      ...param.params,
    }
    let { url: reqUrl } = param
    if (store.getters.config?.common?.convertUrl) {
      reqUrl = store.getters.config.common.convertUrl(reqUrl)
    }
    let params = {
      url: isType(reqUrl, 'string') ? reqUrl : reqUrl(param.params), // 兼容restful接口
      method: param.method,
    }
    if (param.method === 'get') {
      params.params = data
    } else if (param.method === 'put' || param.method === 'post' || param.method === 'patch') {
      if (param.isUpload) {
        var formData = new FormData()

        for (var key in data) {
          formData.append(key, data[key])
        }
        params.data = formData

        params.headers = {
          'Content-Type': 'multipart/form-data',
        }
      } else {
        params.data = data
      }
    } else {
      params.data = data
    }
    return this.ajax(params)
  }
}

// 处理restful地址
export function getRestUrl(url, data) {
  return url.replace(/{([\s\S^}]+?)}/g, (arg, $1) => {
    let v = data[$1]
    delete data[$1]
    return v
  })
}
