<template>
  <div>
    <div class="public-card">
      <jdb-financing-status-bar
        :class="{ 'mt-48': detail.payState !== 'PAID' }"
        :iconsConfig="iconsConfig"
        :repaymentAmount="detail.validAmount"
        title=""
        :type="type"
      >
        <template #bottom>
          <div class="receivable-tip">应收金额</div>
          <div class="jdb-financing-status-bar__bottom">
            <span class="jdb-financing-status-bar__label">融资比率：</span>
            <span class="jdb-financing-status-bar__value">{{ detail.financeRate | formatRate }}</span>
            <span class="jdb-financing-status-bar__label">可融资额度：</span>
            <span class="jdb-financing-status-bar__value">{{ formatCurrency(detail.canFinanceAmount) }}</span>
          </div>
        </template>
      </jdb-financing-status-bar>
      <jdb-title-container size="small" title="基本信息">
        <jdb-info-display :data="detail" :labels="labels"></jdb-info-display>
      </jdb-title-container>
    </div>
    <div class="public-card">
      <jdb-title-container size="small" title="融资列表">
        <jdb-table
          class="mt-8"
          :columns="columns"
          :list="dataList"
          :loading="loading"
          :pagination="pagination"
          @page-change="changePage"
          @size-change="changePageSize"
        >
        </jdb-table>
      </jdb-title-container>
    </div>
  </div>
</template>
<script lang="jsx">
// import Process from '@/assets/images/state/process.png'
import Auditing from '@/assets/images/state/auditing.png'
import Paid from '@/assets/images/state/paid.png'
import Fail from '@/assets/images/state/fail.png'
import Unpay from '@/assets/images/state/unpay.png'
import service from '@/service'
import normalMixin from '@/mixins/normalMixin'
import TableVue from '@/views/components/Table.vue'
import { payState } from '@/utils/enum'
export default {
  name: 'receivable-detail',
  components: {
    jdbTable: TableVue,
  },
  data() {
    return {
      Process: '//storage.360buyimg.com/jdt-business/images/statusBar/loaning.png',
      // Paid: '//storage.360buyimg.com/jdt-business/images/statusBar/loan-success.png',
      detail: {},
      repayDetail: {},
      orderDetail: {},
      component: null,
      loading: false,
      labels: [
        {
          name: 'originNo',
          label: '应收账款编号',
        },
        {
          name: 'sellerName',
          label: '卖方企业名称',
        },
        {
          name: 'buyerName',
          label: '买方企业名称',
        },
        {
          name: 'state',
          label: '应收状态',
          formatter: data => this.enumFilter(data.state, 'receivableState')
        },
        {
          name: 'validAmount',
          label: '应收金额',
          formatter: data => `${this.formatCurrency(data.validAmount)}元`
        },
        {
          name: 'financeRate',
          label: '融资比率',
          formatter: data => this.formatRate(data.financeRate),
        },
        {
          name: 'payState',
          label: '融资状态',
          formatter: data => this.enumFilter(data.payState, 'SUBJECT_PAY_STATE_YSRZ'),
        },
        {
          name: 'canFinanceAmount',
          label: '可融资额度',
          formatter: data => `${this.formatCurrency(data.canFinanceAmount)}元`
        }
      ],
      iconsConfig:{
        info:null,
        default:Paid,
        danger:Fail
      },
      columns:[{
        name:'融资编号',
        dataIndex:'financeNo',
        width: '200px',
      },
      {
        name:'融资金额(元)',
        dataIndex:'amount',
        render:({row})=>this.formatCurrency(row.amount)
      },
      {
        name:'融资申请时间',
        dataIndex:'applyTime',
        render:({row})=>this.formatDate(row.applyTime)
      },
      {
        name:'收款方名称',
        dataIndex:'payee',
      },
      {
        name:'收款方账号',
        dataIndex:'payeeAccount',
      },
      {
        name:'状态',
        dataIndex:'financeState',
        render:({row})=>this.enumFilter(row.financeState, 'financeState'),
      },
      {
        name:'操作',
        fixed:'right',
        width: '78px',
        render: ({row})=>(
          <div>
            <router-link to={{name:'loan-detail', params:{financeNo: row.financeNo}}}>详情</router-link>
          </div>
        )
      }
      ]
    }
  },
  mixins: [normalMixin],
  computed: {
    type() {
      return payState[this.detail.payState]?.type || 'default'
    },
  },
  methods: {
    async doQuery(pagination) {
      const params = {
        subjectNo: this.$route.params.subjectNo,
        pageParam: pagination,
      }
      let [status, real] = await service
        .queryReceivableDetailList(params)
        .queryReceivableDetail(params)
        .executeParallel()

      switch(real.payState) {
        case 'UNPAY':
          this.iconsConfig.info = Unpay
          break;
        case 'PROCESS':
          this.iconsConfig.info = this.Process
          break;
        case 'AUDITING':
          this.iconsConfig.info = Auditing
          break;
      }
      this.dataList = status.financeDTOList
      this.detail = real
      this.pagination.total = status.pageParam?.total||0
    },
  },
  mounted() {
    this.handleQuery()
  },
}
</script>

<style lang="less">
.page-home-yfz {
  background: none;
}
.receivable-tip {
  margin-top: -15px;
}
.jdb-financing-status-bar {
  padding: 10px 32px 36px;
}
.jdb-financing-status-bar__amount {
  font-family: JDZhengHT;
}
</style>
