import Vue from 'vue'
import VueRouter from 'vue-router'
import { changeTheme, getProductCode, scrollToStart, setProductCode } from '@/utils/index.js' //getProductCode
import service from '@/service'
import store from '@/store'
import children from './children'
import config from '@/config/index'

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const modules = import.meta.globEager('./*.router.js')

const childRoutes = children.main
//一级路由
const routes = [
  {
    // 注册页
    path: '/register',
    name: 'register',
    component: () => import('../views/register/index.vue'),
    meta: {
      needLogin: false,
    },
  },
  {
    // 登录页
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
    meta: {
      needLogin: false,
    },
  },
  {
    // 找回密码
    path: '/remember',
    name: 'remember',
    component: () => import('../views/remember/index.vue'),
    meta: {
      bgWhite: true,
      needLogin: false,
    },
  },
  {
    // 修改密码
    path: '/change',
    name: 'change',
    component: () => import('../views/change/index.vue'),
    meta: {
      bgWhite: true,
    },
  },
  {
    // 首页，登录后
    path: '/main',
    name: 'main',
    component: () => import('../views/main.vue'),
    children: [], //...childRoutes
  },
  {
    // 客户中心
    path: '/customer',
    name: 'customer-center',
    component: () => import('../views/customer-center/index.vue'),
    redirect: '/cumtomer/enterprise',
    children: children['customer-center'],
  },
]
//添加一级路由
for (let index in modules) {
  modules[index].default.forEach(item => {
    if (config.checkRoute && config.checkRoute(item)) {
      //子路由
      childRoutes.push(item)
    } else {
      routes.push(item)
    }
  })
}

function reloadPage(param) {
  config.common?.reload?.reloadPage(param)
}

const whiteList = new Set([
  '/',
  '/ys-landing',
  '/cg-landing',
  '/flow/:node/:step?',
  '/contract-middle',
  '/contract-third',
])

function createRouter(base = '/', mode = 'history') {
  const router = new VueRouter({
    mode,
    base,
    routes,
  })
  router.beforeEach(async (to, from, next) => {
    // let productType = getProductType('productType')
    if (window.location.href.indexOf('/scf/index') != -1) {
      setProductCode('')
    }
    //  && productType !== 'all'
    // setProductType('')
    let productCode = getProductCode()
    // 设置主题getStorage('theme') ||
    let theme = config?.primaryColor || 'default'
    changeTheme(theme)

    // 设置背景颜色
    if (to.meta.bodyColor) {
      document.body.style.background = to.meta.bodyColor
      document.documentElement.style.background = to.meta.bodyColor
    }

    //通过路由配置，来获取对应页面的字典[],给字典赋上默认值[]
    for (const key of to.meta.enum || []) {
      !store.getters.enumData[key] && (store.getters.enumData[key] = [])
    }
    let arr = Object.keys(store.getters.enumData)
    Object.keys(store.getters.enumData).map((key, index) => {
      !store.getters.enumData[key].length && (arr[index] = undefined)
    })
    let params = (to.meta.enum || []).filter(v => !arr.includes(v))
    params.length && store.dispatch('enum/getEnum', params)

    if (store.getters.userInfo.username === undefined) {
      try {
        let user = await service.queryLoginUserInfo().executeSerial()
        await store.dispatch('user/updateUser', user)
      } catch (error) {
        console.log(error)
        await store.dispatch('user/updateUser', { login: false })
      }
    }

    //此处逻辑有点复杂
    //无需登录的页面，无需产品编码
    //无需登录的页面，直接进入
    console.log(to)
    if (to.meta.needLogin === false) {
      next()
      if (window.location.href.indexOf('type=home') == -1 && window.location.href.indexOf('contract') == -1) {
        window.location.href = '/scf/?type=home'
      }
    } else {
      //查询产品列表
      if (store.getters.allProducts === undefined) {
        let prodctList = await service.queryProdctList({ userNo: store.getters.userInfo.userNo }).executeSerial()
        await store.dispatch('product/setProduct', prodctList)
      }
      //需要登录的地址，判断是否有登录态
      //如果已经登录
      if (store.getters.userInfo.login) {
        //需要登录的页面，需要产品编码，如果没有产品编码，则跳转到index页面
        if (!productCode) {
          next({ name: 'index' })
        } else {
          //如果有产品编码，
          //尚未更新productInfo
          if (!store.getters.productInfo.productCode) {
            let status = await service.queryUserProduct({ productCode }).executeSerial()
            const { productStatus, productName, productType, productTypeName, productRole, productRoleName } = status
            //更新产品角色相关信息
            await store.dispatch('system/setProductInfo', {
              productCode,
              productStatus,
              productName,
              productType,
              productTypeName,
              productRole,
              productRoleName,
            })

            //如果产品已经开通成功，则需要调取Menu信息，并且加载相关路由表
            if (productStatus === 'OPENED') {
              if (!store.getters.menu.length) {
                let existRoutes = router.getRoutes().reduce((p, c) => {
                  return { ...p, [c.name]: true }
                }, {})

                try {
                  let menus = await service.getProductMenus({ productCode }).executeSerial()
                  if (menus) {
                    store.dispatch('system/setMenu', menus)
                    childRoutes.forEach(m => {
                      /* 宜发展要求，暂时注释权限功能 */
                      // if (menus.find(n => n.path === m.path || (n.urlPerm || '').split(',').includes(m.path))) {
                      if (!existRoutes[m.name]) {
                        router.addRoute('main', m)
                        existRoutes[m.name] = true
                      }
                      // }
                    })
                    if (!existRoutes['default']) {
                      router.addRoute({
                        path: '*',
                        name: 'default',
                        redirect: '/',
                      })
                      existRoutes['default'] = true
                    }

                    next({
                      ...to,
                      replace: true,
                    })
                    return
                  }
                } catch (error) {
                  next()
                }
              }

              //因为已经开通，并且登录成功了，所以如果已经登录，并且要进入登录页面，则跳转到home页
              if (to.name === 'login') {
                next({ name: 'home-index' })
              } else {
                next()
              }
            } else {
              //产品未开通，根据状态跳转
              if (whiteList.has(to.path)) {
                next()
              } else {
                //如果开通中
                if (productStatus === 'OPENING') {
                  //目标是首页跳转到开通，其他继续
                  if (to.path === '/home' || to.name === 'home-index') {
                    next({ name: 'open' })
                  } else {
                    next()
                  }
                } else {
                  next({ name: `${productType.toLowerCase()}-landing` })
                }
                if (config.common?.reload) {
                  reloadPage({
                    ...store.getters.productInfo,
                    to: to,
                    next: next,
                  })
                }
              }
            }
          } else {
            next()
            if (config.common?.reload) {
              if (window.location.href.indexOf('/home') !== -1) {
                reloadPage({
                  ...store.getters.productInfo,
                  to: to,
                  next: next,
                })
              }
            }
          }
        }
      } else {
        //需要登录，但是未登录
        next({ name: 'login' })
      }
    }
  })

  router.afterEach(() => {
    scrollToStart()
  })

  /**
   * 埋点pv上报
   */
  // router.afterEach((to, from) => {})
  return router
}

export default createRouter

// eslint-disable-next-line no-undef
export const defaultRouter = createRouter(__ROUTERPATH__)

export function resetRouter() {
  const newRouter = createRouter()
  defaultRouter.matcher = newRouter.matcher
}
