/**
 * 应收账款
 */
export default [
  {
    /**
     * 应收账款管理
     * http://10.222.95.129/#/view/q2RE9Bzg
     */
    name: 'queryReceivableList',
    method: 'post',
    url: '/api/page/receivable/management/pageList',
  },
  {
    /**
     * 应收账款详情基本信息
     * http://10.222.95.129/#/view/q2RE9Bzg
     */
    name: 'queryReceivableDetail',
    method: 'post',
    url: '/api/page/receivable/management/findDetailBySubjectNo',
  },
  {
    /**
     * 应收账款详情融资信息
     * http://10.222.95.129/#/view/q2RE9Bzg
     */
    name: 'queryReceivableDetailList',
    method: 'post',
    url: '/api/page/receivable/management/pageFinanceListBySubjectNo',
  },
  {
    /**
     * 应收账款确权我的供应商
     * http://10.222.95.129/#/view/q2RE9Bzg
     */
    name: 'querySupplierList',
    method: 'post',
    url: '/api/page/receivable/management/supplier',
  },
  {
    /**
     * 查询确权端额度
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=627872341627a00967237eb3
     */
    name: 'queryConfirmLimit',
    method: 'post',
    url: '/api/page/receivable/confirmation/queryConfirmLimit',
  },
  {
    /**
     * 确权端—首页—确权数
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6278c6941627a00967237ecb
     */
    name: 'queryConfirmNum',
    method: 'post',
    url: '/api/page/receivable/queryConfirmNum',
  },
  {
    /**
     * 确权端—首页—已授信数
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6278c73f7a82f9036757c26b
     */
    name: 'queryCreditNum',
    method: 'post',
    url: '/api/page/receivable/queryCreditNum',
  },
  {
    /**
     * 确权列表查询
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6278c73f7a82f9036757c26b
     */
    name: 'queryConfirmList',
    method: 'post',
    url: '/api/page/confirmation/pageList',
  },
  {
    /**
     * 融资端首页待还款列表查询
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6278c7777a82f9036757c26d
     */
    name: 'queryWaitFinanceList',
    method: 'post',
    url: '/api/page/repay/query/pageQueryWaitFinanceList',
  },
  {
    /**
     * 融资端首页可融资列表查询
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6278c7777a82f9036757c26d
     */
    name: 'queryCanFinanceList',
    method: 'post',
    url: '/api/page/receivable/home/pageCanFinanceList',
  },
  {
    /**
     * 确权管理-详情-融资单信息
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6278c7777a82f9036757c26d
     */
    name: 'queryReceiveConfirmFinanceDetail',
    method: 'post',
    url: '/api/page/confirmation/financedetail',
  },
  {
    /**
     * 确权管理-详情-应收信息
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6278c7777a82f9036757c26d
     */
    name: 'queryReceiveConfirmReceivableDetail',
    method: 'post',
    url: '/api/page/confirmation/receivabledetail',
  },
  {
    /**
     * 确权管理-详情-确权合同
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6278c7777a82f9036757c26d
     */
    name: 'queryReceiveConfirmContract',
    method: 'post',
    url: '/api/page/confirmation/contract',
  },
  {
    /**
     * 确权端-用户确权请求
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6278c7777a82f9036757c26d
     */
    name: 'receivableUserConfirm',
    method: 'post',
    url: '/api/page/receivable/confirmation/userConfirmation',
  },
  {
    /**
     * 确权端-用户确权拒绝
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6278c7777a82f9036757c26d
     */
    name: 'receivableUserRejectConfirm',
    method: 'post',
    url: '/api/page/receivable/confirmation/userRejectConfirmation',
  },
  {
    /**
     * 应收融资-查询已确权的还款记录
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6278c7777a82f9036757c26d
     */
    name: 'queryConfirmRepayRecord',
    method: 'post',
    url: '/api/page/repay/query/queryConfirmRepayRecord',
  },
]
