//准入
export default [
  {
    //按融资端还款
    path: '/repay/apply/:financeNo',
    name: 'repay-byno',
    component: () => import('../views/repay/index.vue'),
  },
  {
    //客户维度还款
    path: '/repay/apply',
    name: 'repay',
    component: () => import('../views/repay/index.vue'),
  },
  {
    //还款详情
    path: '/repay/result/:repayNo',
    name: 'repay-result',
    component: () => import('../views/repay/RepayResult.vue'),
  },
]
