/**
 * 准入接口
 * */
export default [
  {
    /**
     * 根据产品编码+用户号查询产品状态 无用户编号默认查询最新关联的用户编号（url:/api/page/userProduct/queryUserProduct）
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=61a9cd71ab40df48e035f058
     */
    name: 'queryUserProduct',
    method: 'post',
    url: '/api/page/userProduct/queryUserProduct',
  },
  {
    /**
     * 查询邀请码信息 (url:api/page/productOpen/productInvite/queryProductInviteCodeInfo)
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=61a9cb8395840a42e010a382
     */
    name: 'queryProductInviteCodeInfo',
    method: 'post',
    url: '/api/page/productOpen/productInvite/queryProductInviteCodeInfo',
  },
  {
    /**
     * 邀请码产品开通
     * http://10.222.95.129/#/view/qXbQa18v
     */
    name: 'inviteCodeOpenProduct',
    method: 'post',
    url: '/api/page/productOpen/productOpen/inviteCodeOpenProduct',
  },
  {
    /**
     * 保存或更新潜客信息
     * http://10.222.95.129/#/view/VzNZkjzY
     */
    name: 'saveProductPotentialCustomerInfo',
    method: 'post',
    url: '/api/page/productOpen/productPotentialCustomer/saveProductPotentialCustomerInfo',
  },
  {
    /**
     * 查询流程树
     * http://10.222.95.129/#/view/L42GZQzW
     */
    name: 'queryAccessProcess',
    method: 'post',
    url: '/api/page/access/accessQuery/queryAccessProcess',
  },
  {
    /**
     * 查询补充信息页面展示内容
     * http://10.222.95.129/#/view/P81Oadzq
     */
    name: 'queryAddition',
    method: 'post',
    url: '/api/page/access/additionalInfo/queryAccessAdditionInfo',
  },
  {
    /**
     * 提交补充信息 url(/api/page/access/additionalInfo/submitAccessAdditionInfo)
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=61c9a49a95840a42e010a7cf
     */
    name: 'submitAddition',
    method: 'post',
    url: '/api/page/access/additionalInfo/submitAccessAdditionInfo',
  },
  {
    /**
     * 查询资质审核的状态 (url: /api/page/access/audit/query)
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=61deb32eab40df48e035fd20
     */
    name: 'queryAuditStatus',
    method: 'post',
    url: '/api/page/access/audit/query',
  },
  {
    /**
     * 查询账户信息 (url: /api/page/access/account/queryAccessAccount)
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6268d7d21627a00967237d5c
     */
    name: 'queryAccessAccount',
    method: 'post',
    url: '/api/page/access/account/queryAccessAccount',
  },
  {
    /**
     * 确认账户信息 (url: /api/page/access/account/confirmAccessAccount)
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6268d8737a82f9036757c0f9
     */
    name: 'confirmAccessAccount',
    method: 'post',
    url: '/api/page/access/account/confirmAccessAccount',
  },
  {
    /**
     * 确认账户类型 (url: /api/page/access/account/queryAccessType)
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6268db297a82f9036757c0fc
     */
    name: 'queryAccessType',
    method: 'post',
    url: '/api/page/access/account/queryAccessType',
  },
  {
    /**
     * 查询额度开通状态 (url: /api/page/access/quota/get)
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=627a4e581627a00967237f25
     */
    name: 'queryAccessQuotaStatus',
    method: 'post',
    url: '/api/page/access/quota/get',
  },
  {
    /**
     * 应收准入额度开通节点状态 (url: /api/page/access/riskquotaconfirm/next)
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6284c3247a82f9036757c4b8
     */
    name: 'confirmAccessQuota',
    method: 'post',
    url: '/api/page/access/riskquotaconfirm/next',
  },
  {
    /**
     * 查询准入状态
     */
    name: 'queryAdditionState',
    method: 'post',
    url: '/api/page/access/additionalInfo/get',
  },
]
