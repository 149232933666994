export const routeConfig = [
  {
    path: '/cg-landing',
    name: 'cg-landing',
    redirect: '/',
    meta: {
      needLogin: false,
    },
  },
  {
    path: '/ys-landing',
    name: 'ys-landing',
    redirect: '/',
    meta: {
      needLogin: false,
    },
  },
  {
    path: '/enterprise',
    name: 'enterprise',
    component: () => import('./components/enterprise/index.vue'),
    meta: {
      title: '企业信息',
      enum: ['relationship'],
    },
  },
  {
    path: '/loan/apply/:bizNo',
    name: 'loan',
    component: () => import('./components/loan/index.vue'),
  },
  // {
  //   path: '/home',
  //   name: 'home-index',
  //   component: () => import('./components/home/index.vue'),
  // },
  // {
  //   // 首页，落地页
  //   path: '/',
  //   name: 'index',
  //   component: () => import('./components/landing/receivable/index.vue'),
  //   meta: {
  //     needLogin: false,
  //   },
  // },
  {
    // 多产品落地页
    path: '/',
    name: 'index',
    component: () => import('./components/landing/index.vue'),
    meta: {
      needLogin: false,
    },
  },
  // 银行账号信息
  {
    path: '/cumtomer/account/bank',
    name: 'cumtomer-account-bank',
    meta: { title: '银行账户信息' },
    component: () => import('@/views/customer-center/account/index.vue'),
  },
]
