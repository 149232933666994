<script lang="jsx">
export default {
  data(){
    return {
      dateRange:null,
      formData:{
        financeNo:'',
        financeState: '',
        applyTimeStart: undefined,
        applyTimeEnd:undefined
      },
      // stateOption:{
      //   INIT:'初始化',
      //   PROCESS:'待审批',
      //   SUCCESS:'融资成功',
      //   FAIl:'融资失败'
      // }
    }
  },
  watch: {
    dateRange(val) {
      if (val && val.length) {
        this.formData.applyTimeStart = val[0]
         this.formData.applyTimeEnd = val[1]
      } else {
        this.formData.applyTimeStart= this.formData.applyTimeEnd = undefined
      }
    },
  },
  methods:{
    handleQuery(){
      this.$emit('query', this.formData)
    }
  },
  render(){
    return (
    <fin-form label-position={this.config.searchLabelPosition} inline={this.config.searchInline} {...{props:{model: this.formData}}} ref="form" label-width="120px">
      <fin-form-item label="融资编号" prop="financeNo">
        <fin-input clearable class="w-192" placeholder="融资编号" value={this.formData.financeNo} on-input={v=>this.formData.financeNo=v}></fin-input>
      </fin-form-item>
      <fin-form-item label="融资状态" prop="financeState">
        <fin-select clearable class="w-192" value={this.formData.financeState} placeholder="融资状态" on-input={v=>this.formData.financeState=v}>
          {this.enumData['financeState'].map(item=>{
            return <fin-option value={item.itemCode} label={item.itemName}></fin-option>
          })}
        </fin-select>
      </fin-form-item>
      <fin-form-item label="申请时间" prop="dateRange">
        <fin-date-picker
          clearable
          value={this.dateRange} on-input={v=>this.dateRange=v}
          class="w-400"
          value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
        >
        </fin-date-picker>
      </fin-form-item>
      <fin-form-item class="text-right">
        <fin-button type="primary" on-click={this.handleQuery.bind(this)}>查询</fin-button>
        <fin-button plain on-click={()=>{this.$refs.form.resetFields();this.dateRange=null}}>重置</fin-button>
      </fin-form-item>
    </fin-form>)
  }
};
</script>
