export default [
  {
    /**
     * 获取联合登录参数信息
     * http://mock.jdfmgt.com/interface/detail?projectId=639a803ff18c169135a6f1cc&interfaceId=639b136203fe468a355df317
     */
    name: 'queryLoginData',
    method: 'post',
    url: '/external/yfz/login/queryLoginData',
  },
  {
    /**
     * 首页获取产品展示
     * http://mock.jdfmgt.com/interface/detail?projectId=639a803ff18c169135a6f1cc&interfaceId=639a875303fe468a355df2b7
     */
    name: 'queryProductOpenInfo',
    method: 'post',
    url: '/external/yfz/access/queryProductOpenInfo',
  },
]
