<template>
  <div>
    <div class="public-card">
      <jdb-repay-status-bar
        :capital="barData.capAmount"
        :interest="barData.intAmount"
        :penaltyInterest="barData.fineAmount"
        :repaymentAmount="barData.totalAmount"
        :type="type"
      >
        <template #title>还款金额(元)</template>
      </jdb-repay-status-bar>
      <jdb-title-container class="mt-24" size="small" title="还款信息">
        <jdb-info-display :data="detail" :labels="labels"></jdb-info-display>
      </jdb-title-container>
    </div>
    <div class="public-card">
      <jdb-title-container class="mt-0" size="small" title="融资单信息">
        <component :is="current"></component>
      </jdb-title-container>
    </div>
  </div>
</template>
<script lang="jsx">
// import normalMixin from '@/mixins/normalMixin'
// import TableVue from '@/views/components/Table.vue'
import service from '@/service'
import { repayState } from '@/utils/enum'
import TableVueCG from '@/views/trade/components/repay/FinancingTableCG.vue'
import TableVueYS from '@/views/trade/components/repay/FinancingTableYS.vue'
export default {
  name: 'trade-repay-detail',
  // mixins: [normalMixin],
  components: {},
  data() {
    return {
      componentMap:{
        CG: TableVueCG,
        YS: TableVueYS,
      },
      detail: {
        repayAmount: {},
      },
      labels: [
        {
          name: 'repayNo',
          label: '还款流水号',
        },
        {
          name: 'repayAmount',
          label: '还款金额',
          formatter: data => {
            return `${this.formatCurrency(data.repayAmount.totalAmount)}元`
          },
        },
        {
          name: 'applyTime',
          label: '还款操作时间',
        },
        {
          name: 'repayAmount',
          label: '还款本金',
          formatter: data => {
            return `${this.formatCurrency(data.repayAmount.capAmount)}元`
          },
        },
        {
          name: 'repayAmount',
          label: '还款利息',
          formatter: data => {
            return `${this.formatCurrency(data.repayAmount.intAmount)}元`
          },
        },
        {
          name: 'repayAmount',
          label: '还款罚息',
          formatter: data => {
            return `${this.formatCurrency(data.repayAmount.fineAmount)}元`
          },
        },
      ],
    }
  },
  computed: {
    type() {
      return repayState[this.detail.repayState]?.type
    },
    barData() {
      return this.detail.repayAmount || {}
    },
    current(){
      //在这里判断产品维度返回不同的组件
      let { productType } = this.$store.getters.productInfo
      return this.componentMap[productType]||TableVueYS
    }
  },
  methods: {
    async queryDetail() {
      let params = { repayNo: this.$route.params.repayNo, productCode: this.productCode }
      this.detail = await service.queryRepayRecordByRepayNo(params).executeSerial()
    },
  },
  mounted() {
    this.queryDetail()
  },
}
</script>

<style></style>
