//融资
export default [
  {
    path: '/trade/loan-detail/:financeNo',
    name: 'loan-detail',
    meta: {
      enum: ['financeDurationType', 'repayMode', 'repayState', 'payWay', 'financeState', 'SUBJECT_PAY_STATE_YSRZ'],
    },
    component: () => import('../views/trade/LoanDetail.vue'),
  },
  {
    path: '/trade/repay-detail/:repayNo',
    name: 'repay-detail',
    meta: {
      enum: ['financeSettleState'],
    },
    component: () => import('../views/trade/RepayDetail.vue'),
  },
  {
    // 应收融资，应收详情
    path: '/trade/receivable-detail/:subjectNo',
    name: 'receivable-detail',
    meta: {
      enum: ['receivableState', 'financeState', 'SUBJECT_PAY_STATE_YSRZ'],
    },
    component: () => import('../views/trade/ReceivableDetail.vue'),
  },
  {
    //确权详情
    path: '/trade/confirm-detail',
    name: 'confirm-detail',
    meta: {
      enum: ['financeDurationType', 'repayMode'],
    },
    component: () => import('../views/trade/ConfirmDetail.vue'),
  },
  {
    //确权合同
    path: '/trade/confirm-contract',
    name: 'confirm-contract',
    component: () => import('../views/trade/ConfirmContract.vue'),
  },
  {
    //复核合同签署
    path: '/credit-flow/credit-contract-review',
    name: 'credit-flow-credit-contract-review',
    component: () => import('../views/credit-flow/CreditContract.vue'),
  },
  {
    //追溯
    path: '/credit-flow/receipt-trace/:subjectNo',
    name: 'credit-flow-receipt-trace',
    meta: {
      enum: [],
      name: '追溯',
    },
    component: () => import('../views/credit-flow/ReceiptTrace.vue'),
  },
]
