<script lang="jsx">
export default {
  data(){
    return {
      dateRange:null,
      formData:{
        repayState: undefined,
        repayDateStart: undefined,
        repayDateEnd: undefined
      }
    }
  },
  watch: {
    dateRange(val) {
      if (val && val.length) {
        this.formData.repayDateStart = this.formatDate(val[0])
        this.formData.repayDateEnd = this.formatDate(val[1])
      } else {
        this.formData.repayDateStart = this.formData.repayDateEnd = undefined
      }
    },
  },
  methods:{
    handleQuery(){
      this.$emit('query', this.formData)
    }
  },
  render(){
    return <fin-form props={{model: this.formData}} ref="form" label-width="120px">
        <fin-form-item label="还款操作时间" prop="dateRange">
          <fin-date-picker
            clearable
            value={this.dateRange} on-input={v=>this.dateRange=v}
            class="w-400"
            end-placeholder="结束日期"
            range-separator="至"
            start-placeholder="开始日期"
            type="daterange"
          >
          </fin-date-picker>
        </fin-form-item>
        <fin-form-item label="还款状态" prop="repayState">
          <fin-select clearable class="w-192"  placeholder="还款状态" value={this.formData.repayState} on-input={v=>this.formData.repayState=v}>
            <fin-option value="REPAYING" label="还款中"></fin-option>
            <fin-option value="REPAY_SUCCESS" label="还款成功"></fin-option>
            <fin-option value="REPAY_FAIL" label="还款失败"></fin-option>
          </fin-select>
        </fin-form-item>
        <fin-form-item class="text-right">
          <fin-button type="primary" on-click={this.handleQuery.bind(this)}>查询</fin-button>
          <fin-button class="ml-10" on-click={()=>{this.$refs.form.resetFields();this.dateRange = [];}}>重置</fin-button>
        </fin-form-item>
    </fin-form>
  }
};
</script>
