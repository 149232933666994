export default [
  {
    path: '/credit-flow/order-create/:quotaNo',
    name: 'credit-flow-order-create',
    meta: {
      enum: ['QuotaUse', 'QuotaShared', 'invoiceType', 'validateResult'],
    },
    component: () => import('../views/credit-flow/OrderCreate.vue'),
  },
  {
    path: '/credit-flow/order-transfer/:quotaNo/:xdNo',
    name: 'credit-flow-order-transfer',
    meta: {
      enum: ['QuotaUse', 'QuotaShared', 'invoiceType', 'validateResult'],
    },
    component: () => import('../views/credit-flow/OrderTransfer.vue'),
  },
  {
    path: '/credit-flow/update-material/:quotaNo/:xdNo',
    name: 'credit-flow-update-material',
    meta: {
      enum: ['QuotaUse', 'QuotaShared', 'invoiceType', 'validateResult'],
    },
    component: () => import('../views/credit-flow/UpdateMaterial.vue'),
  },
  {
    path: '/credit-flow/result/:state',
    name: 'credit-flow-result',
    component: () => import('../views/credit-flow/OrderResult.vue'),
  },
  {
    path: '/credit-flow/issue-order-detail/:xdNo',
    name: 'credit-flow-issue-order-detail',
    meta: {
      title: '信单详情',
      enum: ['validateResult'],
    },
    component: () => import('../views/credit-flow/OrderDetail.vue'),
  },
  {
    path: '/credit-flow/receive-order-detail/:xdNo',
    name: 'credit-flow-receive-order-detail',
    meta: {
      title: '信单详情',
      enum: ['validateResult'],
    },
    component: () => import('../views/credit-flow/OrderDetail.vue'),
  },
  {
    path: '/credit-flow/transfer-order-detail/:xdNo',
    name: 'credit-flow-transfer-order-detail',
    meta: {
      title: '信单详情',
      enum: ['validateResult'],
    },
    component: () => import('../views/credit-flow/OrderDetail.vue'),
  },
  {
    path: '/credit-flow/issue-order-record-detail/:xdNo',
    name: 'credit-flow-issue-order-record-detail',
    meta: {
      title: '开单交易详情',
      enum: ['validateResult'],
    },
    component: () => import('../views/credit-flow/OrderDetail.vue'),
  },
  {
    path: '/credit-flow/transfer-record-detail/:xdNo',
    name: 'credit-flow-transfer-record-detail',
    meta: {
      title: '交易详情',
      enum: ['validateResult'],
    },
    component: () => import('../views/credit-flow/OrderDetail.vue'),
  },
  {
    path: '/credit-flow/issue-review/:xdNo',
    name: 'credit-flow-issue-review',
    meta: {
      title: '开具复核',
      enum: ['validateResult'],
    },
    component: () => import('../views/credit-flow/OrderDetail.vue'),
  },
  {
    path: '/credit-flow/transfer-review/:xdNo',
    name: 'credit-flow-transfer-review',
    meta: {
      title: '转让复核',
      enum: ['validateResult'],
    },
    component: () => import('../views/credit-flow/OrderDetail.vue'),
  },
  {
    path: '/credit-flow/sign-order/:xdNo',
    name: 'credit-flow-sign-order',
    meta: {
      title: '签收信单',
      enum: ['validateResult'],
    },
    component: () => import('../views/credit-flow/OrderDetail.vue'),
  },
]
