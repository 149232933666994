<script lang="jsx">
import { mapGetters } from 'vuex'
export default {
  data(){
    return {
      dateRange:null,
      formData:{
        financeNo:undefined,
        contractFileNo:undefined,
      }
    }
  },
  computed: {
    ...mapGetters(['productInfo'])
  },
  methods:{
    handleQuery(){
      this.$emit('query', this.formData)
    }
  },
  render(){
    return <fin-form label-position={this.config.searchLabelPosition} inline={this.config.searchInline} {...{props:{model: this.formData}}} ref="form" class="mt-24" label-width="120px">
    {
      this.productInfo.productRole==='LOAN'?
        <fin-form-item label="合同编号" prop='financeNo'>
          <fin-input class="w-192" value={this.formData.financeNo} on-input={v=>this.formData.financeNo=v}  placeholder={'合同编号'}></fin-input>
        </fin-form-item> :
        <fin-form-item label="合同编号" prop='contractFileNo'>
          <fin-input class="w-192" value={this.formData.contractFileNo} on-input={v=>this.formData.contractFileNo=v}  placeholder={'合同编号'}></fin-input>
        </fin-form-item>
      }
      <fin-form-item class="text-right">
        <fin-button type="primary" on-click={this.handleQuery.bind(this)}>查询</fin-button>
        <fin-button on-click={()=>{this.$refs.form.resetFields()}}>重置</fin-button>
      </fin-form-item>
    </fin-form>
  }
};
</script>
