<template>
  <!-- && repayDetail.financeStatus === 'NO_SETTLE' -->
  <div>
    <fin-alert
      v-if="detail.financeState === 'SUCCESS' && detail.settleState === 'N'"
      class="mb-24"
      :closable="false"
      :type="alertContent.alertType"
    >
      <div class="flex">
        <fin-tag v-if="alertContent.tag" class="mr-8" :type="alertContent.type">{{ alertContent.tag }}</fin-tag>

        <div class="flex-1">
          <div v-for="(item, index) in alertContent.list" :key="item.period" class="flex" :class="{ 'mt-8': index }">
            <p class="flex-1 text-black text-opacity-85">
              <span v-if="item.period" class="mr-8">{{ item.period }}</span>
              {{ item.displayText }}：<span>{{ item.total }}</span>
              <span class="text-black text-opacity-65 mr-8">
                元（本金<span>{{ item.cap }}</span
                >元、利息<span>{{ item.int }}</span
                >元、罚息<span>{{ item.fine }}</span
                >元）
              </span>
              <template v-if="item.recentRepayDate">
                <span class="text-black text-opacity-65">当期还款日：</span>{{ item.recentRepayDate }}
              </template>
            </p>
            <fin-button
              v-if="detail.settleState === 'N'"
              slot="customize"
              class="float-right"
              size="small"
              :type="item.type"
              @click="handleRepay"
              >去还款</fin-button
            >
          </div>
        </div>
      </div>
    </fin-alert>
    <div class="public-card">
      <jdb-financing-status-bar
        :class="{
          'mt-24': !(
            detail.financeState === 'SUCCESS' &&
            detail.settleState === 'N' &&
            repayDetail.financeStatus === 'NO_SETTLE'
          ),
        }"
        :iconsConfig="iconsConfig"
        :repaymentAmount="detail.amount"
        title="融资单金额(元)"
        :type="type"
      >
        <template #bottom>
          <div class="jdb-financing-status-bar__bottom">
            <span class="jdb-financing-status-bar__label">融资申请日期：</span>
            <span class="jdb-financing-status-bar__value">{{ detail.applyTime | formatDate }}</span>
            <span class="jdb-financing-status-bar__label">融资利率：</span>
            <span class="jdb-financing-status-bar__value">{{ detail.yearRate | formatRate }}</span>
            <span class="jdb-financing-status-bar__label">融资期限：</span>
            <span class="jdb-financing-status-bar__value">{{ detail.financeDuration }}</span>
            <span class="jdb-financing-status-bar__label">预估到期日：</span>
            <span class="jdb-financing-status-bar__value">{{ detail.dueDate | formatDate }}</span>
          </div>
        </template>
      </jdb-financing-status-bar>
      <jdb-title-container size="small" title="融资单信息">
        <jdb-info-display :data="detail" :labels="labels"></jdb-info-display>
      </jdb-title-container>
    </div>
    <div class="public-card">
      <jdb-title-container v-if="orderTitle" size="small" :title="orderTitle">
        <jdb-info-display :data="orderDetail" :labels="relationLabels"></jdb-info-display>
      </jdb-title-container>
    </div>
    <div v-if="this.detail.financeState === 'SUCCESS'" class="public-card">
      <fin-tabs v-model="activeName" class="min-h-200" size="large">
        <fin-tab-pane label="还款计划" name="plan">
          <jdb-repay-plan-plus class="mt-16" :plan-list="planList" type="detail">
            <template #default="{ plan }">
              <template v-if="plan.financeStatus === 'SETTLE'">
                <div class="jdb-repay-plan-plus__right-item">
                  已还金额：
                  <span class="jdb-repay-plan-plus__right-total">
                    {{ plan.repayAmount.totalAmount | formatCurrency }}
                  </span>
                </div>
                <div class="jdb-repay-plan-plus__right-item">
                  含本金：<span>
                    {{ plan.repayAmount.capAmount | formatCurrency }}
                  </span>
                </div>
                <div class="jdb-repay-plan-plus__right-item">
                  含利息：<span>
                    {{ plan.repayAmount.intAmount | formatCurrency }}
                  </span>
                </div>
                <div v-if="plan.repayAmount.fineAmount" class="jdb-repay-plan-plus__right-item">
                  含罚息：<span>
                    {{ plan.repayAmount.fineAmount | formatCurrency }}
                  </span>
                </div>
              </template>
              <template v-else>
                <div class="jdb-repay-plan-plus__right-item">
                  应还金额：
                  <span class="jdb-repay-plan-plus__right-total">
                    {{ plan.waitRepayAmount.totalAmount | formatCurrency }}
                  </span>
                </div>
                <div class="jdb-repay-plan-plus__right-item">
                  含本金：<span>
                    {{ plan.waitRepayAmount.capAmount | formatCurrency }}
                  </span>
                </div>
                <div class="jdb-repay-plan-plus__right-item">
                  含利息：<span>
                    {{ plan.waitRepayAmount.intAmount | formatCurrency }}
                  </span>
                </div>
                <div v-if="plan.waitRepayAmount.fineAmount" class="jdb-repay-plan-plus__right-item">
                  含罚息：<span>
                    {{ plan.waitRepayAmount.fineAmount | formatCurrency }}
                  </span>
                </div>
              </template>
            </template>
          </jdb-repay-plan-plus>
        </fin-tab-pane>
        <fin-tab-pane label="还款记录" name="history">
          <jdb-table
            class="mt-8"
            :columns="columns"
            :list="dataList"
            :loading="loading"
            :pagination="pagination"
            @page-change="changePage"
            @size-change="changePageSize"
          >
          </jdb-table>
        </fin-tab-pane>
      </fin-tabs>
    </div>
  </div>
</template>
<script lang="jsx">
import service from '@/service'
import normalMixin from '@/mixins/normalMixin'
import TableVue from '@/views/components/Table.vue'
import { financeState } from '@/utils/enum'
export default {
  name: 'trade-loan-detail',
  components: {
    jdbTable: TableVue,
  },
  data() {
    return {
      detail: {},
      repayDetail: {},
      orderDetail: {},
      cashVoucherDetail:{},
      component: null,
      loading: false,
      labels: [
        {
          name: 'financeNo',
          label: '融资单编号',
        },
        {
          name: 'amount',
          label: '融资单金额',
          formatter: data => {
            return `${this.formatCurrency(data.amount)}元`
          },
        },
        {
          name: 'applyTime',
          label: '融资日期',
          formatter: data => this.formatDate(data.applyTime),
        },
        {
          name: 'financeDuration',
          label: '融资期限',
        },
        {
          name: 'yearRate',
          label: '融资利率',
          formatter: data => `${this.formatRate(data.yearRate)}/年`,
        },
        {
          name: 'repayMode',
          label: '还款方式',
          formatter: data => this.enumFilter(data.repayMode, 'repayMode'),
        },
        {
          name: 'dueDate',
          label: '预估到期日',
          formatter: data => this.formatDate(data.dueDate),
        },
        {
          name: 'financeState',
          label: '状态',
          formatter: data => this.enumFilter(data.financeState, 'financeState'),
        },
        {
          name: 'payee',
          label: '收款方名称',
        },
        {
          name: 'payeeAccount',
          label: '收款方账号',
        },
      ],
      orderLabels: [
        {
          name: 'orginNo',
          label: '应收账款编号',
        },
        {
          name: 'sellerName',
          label: '卖方名称',
        },
        {
          name: 'buyerName',
          label: '买方名称',
        },
        {
          name: 'validAmount',
          label: '应收金额',
        },
        {
          name: 'expireDate',
          label: '应收到期日',
        },
        {
          name: 'purchaseNo',
          label: '采购单号',
        },
        {
          name: 'seller',
          label: '卖方企业名称',
        },
        {
          name: 'buyer',
          label: '买方企业名称',
        },
        {
          name: 'purchaseAmount',
          label: '采购金额',
        },
        {
          name: 'thirdOrderNo',
          label: '三方订单接口',
        },
        {
          name: 'thirdOrderInfo',
          label: '三方订单信息',
        },
        {
          name: 'buyerCustomerNo',
          label: '买方企业客户编号',
        },
        {
          name: 'payState',
          label: '支付状态',
          formatter: data =>this.enumFilter(data.payState, 'SUBJECT_PAY_STATE_YSRZ'),
        },
        {
          name: 'payee',
          label: '收款账户名',
        },
        {
          name: 'payeeAccount',
          label: '收款账号',
        },
        {
          name: 'purchaseTime',
          label: '采购日期',
          formatter: data => this.formatDate(data.purchaseTime),
        },
      ],
      voucherLabels:[],
      planList: [],
      activeName: 'plan',
      iconsConfig: {
        info: '//storage.360buyimg.com/jdt-business/images/statusBar/loaning.png',
        default: '//storage.360buyimg.com/jdt-business/images/statusBar/loan-success.png',
        danger: '//storage.360buyimg.com/jdt-business/images/statusBar/loan-fail.png',
      },
      columns: [
        {
          name: '还款单号',
          dataIndex: 'repayNo',
        },
        {
          name: '还款金额(元)',
          dataIndex: 'repayAmount',
          align: 'right',
          width: '160px',
          render: ({ row }) => {
            return this.formatCurrency(row.repayAmount.totalAmount)
          },
        },
        {
          name: '支付方式',
          dataIndex: 'repayWay',
          width: '160px',
          render: ({ row }) => this.enumFilter(row.repayWay, 'payWay')
        },
        {
          name: '还款状态',
          dataIndex: 'repayRecordStatus',
          width: '160px',
          render: ({ row }) => this.enumFilter(row.repayState, 'repayState'),
        },
        {
          name: '还款操作时间',
          dataIndex: 'applyTime',
          width: '180px',
          render: ({ row }) => this.formatDate(row.applyTime),
        },
        {
          name: '操作',
          width: '130px',
          render: ({ row }) => {
            let detailPath = {name: 'repay-detail', params: {repayNo: row.repayNo, }, }
            let {resolved} = this.$router.resolve(detailPath)
            return (
              <div>
                <router-link
                  target={resolved.matched.length > 1 ? undefined: '_blank'}
                  to={detailPath}
                >
                  详情
                </router-link>
              </div>
            )
          },
        },
      ],
    }
  },
  mixins: [normalMixin],
  computed: {
    type() {
      return financeState[this.detail.financeState]?.type || 'default'
    },
    relationLabels() {
      // 上环境后 注意any object 对象嵌套
      return this.orderLabels.filter(m => {
        return !!this.orderDetail[m.name]
      })
    },
    orderTitle() {
      let { bizType } = this.detail
      if (bizType) {
        return { YS: '应收单信息', CG: '采购单信息' }[bizType]
      } else {
        return false
      }
    },
    alertContent() {
      let { financeStatus, currentPeriod, totalPeriod, financeRepayPlans } = this.repayDetail
      let rst = {
        tag: '最近待还',
        financeStatus,
        alertType: 'primary',
      }
      let waitRepayAmount = ((financeRepayPlans && financeRepayPlans.find(item => item.period == currentPeriod)) || {}).waitRepayAmount
      if (!waitRepayAmount) return rst
      let list = []

      ;(financeRepayPlans||[]).forEach(m=>{
        if(m.financeStatus==='SETTLE') return
        let waitRepayAmount = m.waitRepayAmount
        let obj = {
          financeStatus: m.financeStatus,
          num: m.period,
          period : `第${m.period}/${totalPeriod}期`,
          total : waitRepayAmount.totalAmount,
          cap : waitRepayAmount.capAmount,
          fine : waitRepayAmount.fineAmount,
          int : waitRepayAmount.intAmount,
          repayDate: m.financeStatus === 'NO_SETTLE'  ? this.formatDate(m.repayDate) : null,
          type:{OVER_DUE:'danger', GRACE:'warning'}[m.financeStatus] || 'primary',
          displayText: {OVER_DUE:'逾期待还', GRACE:'宽限期待还'}[m.financeStatus] || '还款日待还金额'
        }

        list.push(obj)
      })
      switch (financeStatus) {
        case 'NO_SETTLE':
          rst.tag = '最近待还'
          rst.alertType = 'primary'
          rst.list = list.filter(m=>m.num === currentPeriod)
          break
        case 'SETTLE':
          rst.tag = '已结清'
          rst.alertType = 'primary'
          break
        case 'OVER_DUE':
          rst.tag = '逾期待还'
          rst.alertType = 'error'
          rst.list = list.filter(m=>m.financeStatus==='OVER_DUE')
          break
        case 'GRACE':
          rst.tag = '宽限期待还'
          rst.alertType = 'warning'
          rst.list = list.filter(m=>m.financeStatus==='GRACE')
          break
      }
      return rst
    },
  },
  methods: {
    async queryDetail(params) {
      this.detail = await service.financeDetail(params).executeSerial()
      if (this.detail.contractList) {
        this.labels.push(
          ...this.detail.contractList.map((m, i) => {
            return {
              label: `合同${['一', '二', '三', '四', '五', '六'][i]}`,
              formatter: () => {
                let cont = (
                  <span>
                    {m.contractName}
                    <a class="ml-8" target="_blank" href={this.convertUrl('/page/file/manage/download', m.fileNo)}>
                      下载
                    </a>
                  </span>
                )
                return cont
              },
            }
          }),
        )
      }

      if (this.detail.financeState === 'SUCCESS') {
        this.repayDetail = await service.queryFinanceRepayDetail(params).executeSerial()
        this.planList = this.repayDetail.financeRepayPlans.map(m => {
          return {
            ...m,
            termCount: this.repayDetail.totalPeriod,
            termNo: m.period,
            endDate: m.repayDate,
            planStatus: { NO_SETTLE: '未结清', OVER_DUE: '已逾期', GRACE: '宽限期', SETTLE: '已结清' }[m.financeStatus],
            currTerm: this.repayDetail.currentPeriod === m.period,
            type: { NO_SETTLE: 'info', OVER_DUE: 'danger', GRACE: 'wraning', SETTLE: 'info' }[m.financeStatus],
          }
        })
      }
      this.detail.financeDuration = `${this.detail.financeDuration}${this.enumFilter(this.detail.periodUnit, 'financeDurationType')}`
      if (this.detail.bizNo && this.detail.bizType) {
        let { bizNo, bizType } = this.detail

        this.orderDetail = await service.financeAssociated({ ...params, bizNo, bizType }).executeSerial()
        this.orderDetail.validAmount = this.orderDetail.validAmount && this.orderDetail.validAmount + '元'
      }
      if(this.detail.cashVoucherState==='Y') {
        this.queryCashVoucher(params)
      }
    },
    /**
     * 查询用款凭证
     */
    async queryCashVoucher(params){
      this.cashVoucherDetail = await service.getCashvoucherDetail(params).executeSerial()

      if(this.cashVoucherDetail) {
        if(this.cashVoucherDetail.bankSlip){
          this.voucherLabels.push({
            label:'银行账户回单',
            formatter: data=>{
              return <span>
                {data.bankSlip.fileName}
                <a class="ml-8" target="_blank" href={this.convertUrl('/page/file/manage/download', data.bankSlip.fileNo)}>
                  下载
                </a>
              </span>
            }
          })
        }
        if(this.cashVoucherDetail.purchaseContract){
          this.voucherLabels.push({
            label:'采购合同',
            formatter: data=>{
              return <span>
                {data.purchaseContract.fileName}
                <a class="ml-8" target="_blank" href={this.convertUrl('/page/file/manage/download', data.purchaseContract.fileNo)}>
                  下载
                </a>
              </span>
            }
          })
        }
        if(this.cashVoucherDetail.invoice){
          this.voucherLabels.push({
            label:'发票',
            formatter: data=>{
              return <span>
                {data.invoice.fileName}
                <a class="ml-8" target="_blank" href={this.convertUrl('/page/file/manage/download', data.invoice.fileNo)}>
                  下载
                </a>
              </span>
            }
          })
        }
      }
    },
    async doQuery(pagination) {
      let { pageParam, repayRecords } = await service
        .queryRepayRecord({
          productCode: this.productCode,
          financeNo: this.$route.params.financeNo,
          pageParam: pagination,
        })
        .executeSerial()
      this.dataList = repayRecords
      this.pagination.total = pageParam.total
    },
    handleRepay() {
      this.$router.push({ name: 'repay-byno', params: { financeNo: this.$route.params.financeNo } })
    },
  },
  mounted() {
    let params = { financeNo: this.$route.params.financeNo, productCode: this.productCode }
    this.queryDetail(params)
    this.handleQuery()
  },
}
</script>

<style lang="less">
.page-home-main__box {
  background: none;
}
.fin-alert__content {
  flex: 1;
}
</style>
