//子路由
export default {
  main: [
    {
      path: '/trade/receivable',
      name: 'trade-receivable',
      meta: {
        title: '应收账款管理',
        enum: ['receivableState', 'SUBJECT_PAY_STATE_YSRZ'],
      },
      component: () => import('../views/trade/ReceivableList.vue'),
    },
    {
      path: '/trade/repay-record',
      name: 'trade-record',
      meta: {
        title: '还款记录',
        enum: ['repayState', 'payWay'],
      },
      component: () => import('../views/trade/RepayRecord.vue'),
    },
    {
      path: '/supplier',
      name: 'receivable-supplier',
      meta: {
        title: '我的供应商',
      },
      component: () => import('../views/supplier/index.vue'),
    },
    {
      path: '/home',
      name: 'home-index',
      meta: {
        enum: [
          'receivableState',
          'repayMode',
          'payWay',
          'xdType',
          'financeDurationType',
          'XdOpenState',
          'ticketCleanState',
          'financeState',
          'repayState',
        ],
      },
      component: () => import('../views/home/product/index.vue'),
    },
    {
      path: '/contract',
      name: 'contract',
      meta: {
        title: '合同管理',
        enum: ['financeState'],
      },
      component: () => import('../views/contract/index.vue'),
    },
    {
      path: '/enterprise',
      name: 'enterprise',
      meta: {
        title: '企业信息',
        enum: ['relationship'],
      },
      component: () => import('../views/enterprise/index.vue'),
    },
    {
      path: '/trade',
      name: 'trade',
      component: () => import('../views/trade/index.vue'),
      children: [
        {
          path: '',
          name: 'trade-index',
          redirect: '/trade/loan',
        },
        {
          path: '/trade/loan',
          name: 'trade-loan',
          meta: {
            title: '交易记录',
            enum: ['financeDurationType', 'financeState'],
          },
          component: () => import('../views/trade/LoanList.vue'),
        },
        {
          path: '/trade/repay',
          name: 'trade-repay',
          meta: {
            title: '交易记录',
          },
          component: () => import('../views/trade/RepayList.vue'),
        },
      ],
    },
    {
      path: '/trade/confirm',
      name: 'trade-confirm',
      meta: {
        title: '确权管理',
      },
      component: () => import('../views/trade/ConfirmList.vue'),
    },
    {
      path: '/credit-flow/personnel',
      name: 'credit-flow-personnel',
      meta: {
        title: '人员管理',
        enum: ['opUserState'],
      },
      component: () => import('../views/credit-flow/Personnel.vue'),
    },
    {
      path: '/credit-flow/order-line',
      name: 'credit-flow-order-line',
      meta: {
        enum: ['QuotaUse', 'QuotaShared'],
      },
      component: () => import('../views/credit-flow/OrderLine.vue'),
    },
    {
      path: '/credit-flow/order-record',
      name: 'credit-flow-orderRecord',
      meta: {
        title: '开单记录',
        enum: ['XdOpenState'],
      },
      component: () => import('../views/credit-flow/MyIssueOrder.vue'),
    },
    {
      path: '/credit-flow/transfer-record',
      name: 'credit-flow-transferRecord',
      meta: {
        title: '转让记录',
        enum: ['xdType', 'XdOpenState'],
      },
      component: () => import('../views/credit-flow/MyIssueOrder.vue'),
    },
    {
      path: '/credit-flow/my-order',
      name: 'credit-flow-myOrder',
      meta: {
        title: '我开出的信单',
        enum: ['XdOpenState', 'ticketCleanState'],
      },
      component: () => import('../views/credit-flow/MyIssueOrder.vue'),
    },
    {
      path: '/credit-flow/my-receive-order',
      name: 'credit-flow-myReceiveOrder',
      meta: {
        // title: '我的信单',
        enum: ['xdType', 'ticketCleanState'],
      },
      component: () => import('../views/credit-flow/MyReceiveOrder.vue'),
    },
  ],
  'customer-center': [
    {
      path: '/cumtomer/enterprise',
      name: 'cumtomer-enterprise',
      meta: { title: '企业信息', enum: ['relationship'] },
      component: () => import('../views/customer-center/enterprise/index.vue'),
    },
    {
      path: '/cumtomer/account',
      name: 'cumtomer-account',
      meta: { title: '银行账户信息' },
      component: () => import('../views/customer-center/account/index.vue'),
    },
  ],
}
