import Vue from 'vue'
import Login from '@/views/login/login.vue'
import store from './store'
import { mapGetters } from 'vuex'
import { Dialog } from '@jdt/find'
import {
  formatCurrency,
  formatDate,
  formatRate,
  formatPhone,
  formatMask,
  getProductCode,
  enumFilter,
} from '@/utils/index.js'
import service from './service'
const baseURL = import.meta.env.VITE_API_URL
const DialogConstructor = Vue.extend(Dialog)

Vue.mixin({
  filters: { formatCurrency, formatDate, formatRate, formatPhone, formatMask, enumFilter },
  computed: {
    ...mapGetters(['userInfo', 'allProducts', 'enumData', 'enumMap', 'config', 'productCode', 'productInfo']),
    openedProducts() {
      return this.allProducts.filter(item => item.userProductState == 'OPENED')
    },
  },
  methods: {
    formatCurrency,
    formatDate,
    formatRate,
    formatPhone,
    formatMask,
    enumFilter,
    getProductCode,
    openLogin(redirectUrl) {
      if (!redirectUrl) {
        redirectUrl = () => {
          service
            .queryUserProduct({ productCode: this.productCode })
            .executeSerial()
            .then(async status => {
              this.jumpByState(status)
            })
        }
      }
      let loginVNode = <Login redirectUrl={redirectUrl}></Login>
      this.openDialog(loginVNode, { customClass: 'login-box' })
    },
    openDialog(vNode, options) {
      const map = {
        primary: { icon: 'info', color: 'primary' },
        success: { icon: 'success', color: 'success' },
        error: { icon: 'error', color: 'danger' },
        info: { icon: 'info', color: 'info' },
        warning: { icon: 'warning', color: 'warning' },
      }
      let instance = new DialogConstructor({
        el: document.createElement('div'),
        propsData: {
          width: '352px',
          visible: true,
          closeOnClickModal: false,
          ...options,
        },
        store: this.$store,
        router: this.$router,
      })
      vNode.componentOptions.listeners = {
        ...vNode.componentOptions.listeners,
        close: () => {
          instance.visible = false
        },
      }
      const hasTitle = options.type || options.contentTitle
      let contentNode = (
        <div>
          {hasTitle ? (
            <p class="flex leading-32">
              {options.type ? (
                <i
                  class={[
                    'text-3xl',
                    'mr-8',
                    `fin-icon-${map[options.type].icon}-solid`,
                    `text-${map[options.type].color}-color`,
                  ]}
                ></i>
              ) : null}
              <span class="text-base text-black text-opacity-85 font-bold">{options.contentTitle}</span>
            </p>
          ) : null}
          <div class={{ 'pl-40': hasTitle }}>{vNode}</div>
        </div>
      )

      instance.$on('close', () => (instance.visible = false))
      instance.$on('closed', () => instance.$el.parentNode.removeChild(instance.$el))
      instance.$slots.default = [contentNode]
      document.body.appendChild(instance.$el)
    },
    // 获取字典，已在router/index.js，写了一个全局的调用
    async getEnum(params = []) {
      await this.$store.dispatch('enum/getEnum', params)
    },
    /**
     * 更新产品编码
     * @param {String} productCode 产品编码
     */
    async updateProductCode(productCode) {
      let flag = this.productInfo.productCode && productCode !== this.productInfo.productCode
      this.$store.dispatch('product/setProductCode', productCode)
      //产品编码发生变化才调用
      if (flag) {
        await this.updateStore()
      }
    },
    async updateStore() {
      if (this.userInfo.login && this.productCode) {
        let productCode = this.productCode
        //查询用户产品状态
        let status = await service.queryUserProduct({ productCode }).executeSerial()
        const { productStatus, productName, productType, productTypeName, productRole, productRoleName } = status

        //更新产品角色相关信息
        this.$store.dispatch('system/setProductInfo', {
          productCode,
          productStatus,
          productName,
          productType,
          productTypeName,
          productRole,
          productRoleName,
        })
      } else {
        this.clearStore()
      }
    },
    /**
     * 清空store
     */
    clearStore() {
      //清空state
      this.$store.dispatch('system/setProductInfo', {})
      this.$store.dispatch('system/setMenu', [])
    },
    /**
     * 状态跳转
     * @param {Object} status 状态
     */
    jumpByState(status) {
      if (status.productStatus === 'UNOPEN' || status.productStatus === 'REJECT') {
        this.$router.push({ name: `${status.productType.toLowerCase()}-landing` })
      } else if (status.productStatus === 'OPENED') {
        this.$router.push({ name: 'home-index' })
      } else {
        this.$router.push({ name: 'open' })
      }
    },
    convertUrl(url, fileNo) {
      // eslint-disable-next-line no-undef
      if (__MODE__ === 'dev') {
        if (fileNo) {
          return `${baseURL}api/${url}/${fileNo}`
        } else {
          return `${baseURL}api/${url}`
        }
      } else {
        if (fileNo) {
          if (!store.getters.config.common.convertUrl) {
            return `${baseURL}api/${url}/${fileNo}`
          } else {
            url = `${url}?fileNo=${fileNo}`
            return store.getters.config.common.convertUrl(url)
          }
        } else {
          if (!store.getters.config.common.convertUrl) {
            return `${baseURL}api/${url}`
          } else {
            return store.getters.config.common.convertUrl(url)
          }
        }
      }
    },
  },
})
