export default [
  {
    /**
     * 查询企业紧急联系人
     */
    name: 'queryEmergencyContact',
    method: 'post',
    url: '/api/page/user/emergencyContact/pageList',
  },
  {
    /**
     * 新增企业紧急联系人
     */
    name: 'addEmergencyContact',
    method: 'post',
    url: '/api/page/user/emergencyContact/save',
  },
  {
    /**
     * 编辑企业紧急联系人
     */
    name: 'updataEmergencyContact',
    method: 'post',
    url: '/api/page/user/emergencyContact/update',
  },
  {
    /**
     * 删除企业紧急联系人
     */
    name: 'deleteEmergencyContact',
    method: 'post',
    url: '/api/page/user/emergencyContact/delete',
  },
]
