export const Regex = {
  /**
   * 数字、字母、下划线至少2种，8-20位
   */
  // password: /^[a-zA-Z_\d](?![\d]+$)(?![a-zA-Z]+$)(?!_+$)[a-zA-Z_\d]{8,20}$/,
  /**
   * 数字、字母、下划线至少4种，8-20位
   */
  /* eslint-disable-next-line no-useless-escape */
  password: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=(?:.*?[!"#$%&'()*+,-./:;<=>?@\[\]^_`{|}~\\]))[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@\[\]^_`{|}~\\]{8,20}$/,
  mobile: /^1[3-9][\d]{9}$/,
  smsVerifyCode: /^[\d]{1,6}$/,
  invoiceCode: /^[\d]{12}$/,
  invoiceNumber: /^[\d]{8}$/,
  invoiceValidateCode: /^[\d]{1,20}$/,
  email: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
  phone: /^(\d{3,4}-\d{7,8})$|(^13[0-9]{9}$)|(^\d{7,8}$)|(^\d{10,12}$)/,
  // 图形验证码
  // imageVerifyCode:
  // 身份证号
  idcard: /(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}[0-9Xx]$)/,
}
